<template>
  <div class="operate-box">
    <div class="header-operate">
      <span
        v-for="(item, index) in buttonGroupList"
        :key="item.property"
        :class="{ 'high-light': whichIndex == index }"
        @click="switchDisplay(index, item.property)"
        ><i :class="item.icon"></i>{{ item.text }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'DynamicSwitching',
  data() {
    return {
      buttonGroupList: [
        {
          icon: 'iconfont icon-tubiaozhanshi',
          text: '图表展示',
          property: 'chart-display' // 图表组件对应的组件名
        },
        {
          icon: 'iconfont icon-liebiaozhanshi',
          text: '列表展示',
          property: 'list-display' // 列表组件对应的组件名
        }
      ],
      whichIndex: 0
    };
  },
  methods: {
    switchDisplay(index, property) {
      this.whichIndex = index;
      this.$emit('switch-display', property);
    }
  }
};
</script>
<style scoped lang="less">
.operate-box {
  position: relative;
  .header-operate {
    position: absolute;
    right: 0;
    top: -56px;
    span {
      display: inline-block;
      width: 108px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #c0c4ce;
      color: #616266;
      .iconfont {
        font-size: 18px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    .high-light {
      background: #005aab;
      border: 1px solid #005aab;
      color: #fff;
    }
  }
}
</style>
