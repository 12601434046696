/*
 * @Author: your name
 * @Date: 2021-11-09 10:25:44
 * @LastEditTime: 2021-12-07 14:08:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\router\general-uesr\3.visualization.js
 */
import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/safety-input',
  name: 'safetyInput',
  component: BasicLayout,
  redirect: '/safety-input',
  meta: {
    roleType: 'security',
    code: 'safety-input',
    title: '安全投入',
    menu: true,
    icon: 'iconfont icon-caidan2-4anquantouru'
  },
  children: [
    {
      path: '/safety-plan',
      name: 'safetyPlan',
      component: () => import('@/views/pages/general-user/security-pages/safety-input/safety-plan'),
      meta: {
        roleType: 'security',
        code: 'safety-plan_list',
        title: '安全经费计划台账',
        menu: true
      }
    },
    {
      path: '/safety-use',
      name: 'safetyUse',
      component: () => import('@/views/pages/general-user/security-pages/safety-input/safety-use'),
      meta: {
        roleType: 'security',
        code: 'safety-use_list',
        title: '安全经费使用台账',
        menu: true
      }
    }
  ]
};

export default homePageMgrRoutes;
