/*
 * @Author: your name
 * @Date: 2021-11-09 10:25:44
 * @LastEditTime: 2021-12-07 14:17:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\router\general-uesr\3.visualization.js
 */
import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/team-building',
  name: 'teamBuilding',
  component: BasicLayout,
  redirect: '/team-building',
  meta: {
    roleType: 'security',
    code: 'team-building',
    title: '队伍建设',
    menu: true,
    icon: 'iconfont icon-caidan2-6duiwujianshe'
  },
  children: [
    {
      path: '/safety-train',
      name: 'safetyTrain',
      component: () => import('@/views/pages/general-user/security-pages/team-building/safety-train'),
      meta: {
        roleType: 'security',
        code: 'safety-train_list',
        title: '安全教育培训',
        menu: true
      }
    }
  ]
};

export default homePageMgrRoutes;
