export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属机构'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择培训时间'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入培训人数'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入培训内容'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入培训人'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入组织单位'));
      }else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };

    return {
      rules: {
        orgId: [{ validator: validateOrgId, trigger: '' }],
        trainDate: [{ validator: validatePost, trigger: '' }],
        personNum: [{ validator: validateProblem, trigger: '' }],
        trainContent: [{ validator: validateSuggestion, trigger: '' }],
        trainPerson: [{ validator: validateHiddenLevelId, trigger: '' }],
        organizationalUnit: [{ validator: validateHiddenTypeId, trigger: '' }],
      }
    };
  }
};
