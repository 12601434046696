import BasicLayout from '@/views/layout/BasicLayout.vue';

const FieldEnvRoutes = {
  path: '/env-field-manage',
  name: 'EnvFieldManage',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-field-manage',
    title: '现场管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-video-monitoring',
      name: 'EnvVideoMonitoring',
      component: () => import('@/views/pages/general-user/env-pages/field-manage/video-monitoring'),
      meta: {
        roleType: 'environment',
        code: 'env-video-monitoring',
        title: '视频监控',
        menu: true
      }
    },
    {
      path: '/env-camera-management',
      name: 'EnvCameraManagement',
      component: () => import('@/views/pages/general-user/env-pages/field-manage/camera-manage'),
      meta: {
        roleType: 'environment',
        code: 'env-camera-management',
        title: '摄像机管理',
        menu: true
      }
    },
    {
      path: '/env-online-monitoring',
      name: 'EnvOnlineMonitoring',
      component: () => import('@/views/pages/general-user/env-pages/files-pages'),
      meta: {
        roleType: 'environment',
        code: 'env-online-monitoring',
        title: '在线监测',
        menu: true
      }
    },
    {
      path: '/env-equipment-manage',
      name: 'EnvEquipmentManage',
      component: () => import('@/views/pages/general-user/env-pages/files-pages'),
      meta: {
        roleType: 'environment',
        code: 'env-equipment-manage',
        title: '设备管理',
        menu: true
      }
    }
  ]
};

export default FieldEnvRoutes;
