import BasicLayout from '@/views/layout/BasicLayout.vue';

const SystemEnvRoutes = {
  path: '/env-system-management',
  name: 'EnvSystemManagement',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-system-management',
    title: '系统管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-organizational-manage',
      name: 'EnvOrganizationalManage',
      component: () => import('@/views/pages/general-user/env-pages/sys-management/org-management'),
      meta: {
        roleType: 'environment',
        code: 'env-organizational-manage',
        title: '机构管理',
        menu: true
      }
    },
    {
      path: '/env-personnel-manage',
      name: 'EnvPersonnelManage',
      component: () => import('@/views/pages/general-user/env-pages/sys-management/user-management'),
      meta: {
        roleType: 'environment',
        code: 'env-personnel-manage',
        title: '人员管理',
        menu: true
      }
    },
    {
      path: '/env-role-manage',
      name: 'EnvRoleManage',
      component: () => import('@/views/pages/general-user/env-pages/sys-management/role-management'),
      meta: {
        roleType: 'environment',
        code: 'env-role-manage',
        title: '角色管理',
        menu: true
      }
    },
    {
      path: '/env-personal-center',
      name: 'EnvPersonalCenter',
      component: () => import('@/views/pages/general-user/env-pages/sys-management/personal-center'),
      meta: {
        roleType: 'environment',
        code: 'env-personal-center',
        title: '个人中心',
        menu: true
      }
    }
  ]
};

export default SystemEnvRoutes;
