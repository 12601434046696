import BasicLayout from '@/views/layout/BasicLayout.vue';

const incidentQURoutes = {
  path: '/quality-incident',
  name: 'qualityIncident',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'quality',
    code: 'quality-quality-incident',	
    title: '质量事故管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/quality-incident',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '质量事故管理',
        menu: false
      }
    },
  ]
};

export default incidentQURoutes;
