function stringify(value) {
  return JSON.stringify(value);
};
function parse(value) {
  return JSON.parse(value);
};
/* Local */
export function setItem(key, value) {
  window.localStorage.setItem(key, stringify(value));
};

export function getItem(key) {
  return parse(window.localStorage.getItem(key));
};

export function removeItem(key) {
  window.localStorage.removeItem(key);
};

export function clear() {
  window.localStorage.clear();
};

/* Session */
export function setSessionItem(key, value) {
  window.sessionStorage.setItem(key, stringify(value));
};

export function getSessionItem(key) {
  return parse(window.sessionStorage.getItem(key));
};

export function removeSessionItem(key) {
  window.sessionStorage.removeItem(key);
};

export function clearSession() {
  window.sessionStorage.clear();
};
/* All */
export function clearAll() {
  clear();
  clearSession();
};
