import axios from 'axios';
import { Message, Notification } from 'element-ui';
import { getItem } from '@/utils/storage.js';
import onLogout from '@/utils/onLogout.js';
import { BASE_URL, APP_CODE, SUCCESS_CODE } from '@/config/index.js';

// TODO: 待约定 退出 code
const LOGOUT_CODE_LIST = [520];
const service = axios.create({
  baseURL: BASE_URL
  //timeout: 5000
});
// 请求拦截
service.interceptors.request.use(
  config => {
    const token = getItem('token');
    const tenantId = getItem('tenantInfo').tenantId;
    const appType = getItem('appType');//1代表安全，2代表环保，3代表职业健康
    config.headers.Accept = '*/*';
    // 请求头
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.AppCode = APP_CODE;
      config.headers.appType = appType;
      config.headers.tenantId = tenantId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  response => {
    // 二进制流文件
    if (response.request.responseType === 'blob') {
      return response;
    }
    const {
      data,
      data: { code }
    } = response;
    if (code && LOGOUT_CODE_LIST.includes(code)) {
      onLogout();
      return;
    }

    // failed
    if (code && code !== SUCCESS_CODE) {
      Message({
        type: 'error',
        message: data.message,
        duration: 3000
      });
      return Promise.reject(data);
    }
    // success
    return data;
  },
  error => {
    // console.log('response error:', error.response); // for debug
    const { code, message } = error.response.data;
    Notification({
      type: 'error',
      title: code,
      message: message,
      duration: 3000
    });
    if (code && LOGOUT_CODE_LIST.includes(code)) {
      onLogout();
      return;
    }
    return Promise.reject(error.response);
  }
);

export default service;
