import request from '@/utils/request.js';
/**
 * 职业危害病监测列表
 * @returns {Promise}
 */
export function getDiseaseList(data) {
  return request({
    url: '/traffic-construction/occupational/getOccupationalPage',
    method: 'post',
    data
  });
}
/**
 * 新增职业危害病监测
 * @returns {Promise}
 */
export function addDisease(data) {
  return request({
    url: '/traffic-construction/occupational/addOccupational',
    method: 'post',
    data
  });
}
/**
 * 修改职业危害病监测
 * @returns {Promise}
 */
 export function editDisease(data) {
  return request({
    url: '/traffic-construction/occupational/editOccupational',
    method: 'post',
    data
  });
}
/**
 * 删除职业危害病监测
 * @returns {Promise}
 */
export function deleteDisease(data) {
  return request({
    url: '/traffic-construction/occupational/deleteOccupational',
    method: 'post',
    data
  });
}
/**
 * 职业危害病监测导出
 * @returns {Promise}
 */
export function exportDisease(data) {
  return request({
    url: '/traffic-construction/occupational/exportOccupationalRecord',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
/* 机构数
 * @returns {Promise}
 */
export function getOrgTree(params) {
  return request({
    url: '/traffic-construction/getRangOrgTree',
    method: 'get',
    params
  });
}
/* 机构列表
 * @returns {Promise}
 */
export function getorgList(params) {
  return request({
    url: '/traffic-construction/getRangOrg',
    method: 'get',
    params
  });
}
/* 当前机构下属机构
 * @returns {Promise}
 */
export function getChildrenOrgList(params) {
  return request({
    url: '/traffic-construction/getDataRangByNature',
    method: 'get',
    params
  });
}
/* 职业病监测图表
 * @returns {Promise}
 */
export function getPhotoData(data) {
  return request({
    url: '/traffic-construction/occupational/getOccupationalPhotoData',
    method: 'post',
    data
  });
}

