<template>
  <div style="margin-top:16px">
    <el-menu
      router
      unique-opened
      background-color="#005aab"
      text-color="#f3f3f3"
      active-text-color="#c9c9c9"
      :default-active="$route.path"
      :default-openeds="defaultOpeneds"
      @select="selectMenu"
      @open="openMenu"
    >
      <div v-for="item in menuList" :key="item.path">
        <aside-menu-item v-if="item.children && item.children.length" :parentItem="item"></aside-menu-item>
        <el-menu-item v-else :index="item.path">
          <i class="iconfont" :class="item.meta.icon" style="margin-right: 6px"></i>
          <span>{{ item.meta.title }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import AsideMenuItem from './AsideMenuItem.vue';

export default {
  name: 'AsideMenu',
  components: {
    AsideMenuItem
  },
  data() {
    return {
      menuList: [],
      defaultOpeneds: []
    };
  },
  mounted() {
    let aside=document.getElementsByClassName('el-aside')[0]
    let ulli = aside.getElementsByTagName('li');
    for (let i = 0; i < ulli.length; i++) {
      ulli[i].addEventListener('mouseenter', this.ulliMouseIn);
      ulli[i].addEventListener('mouseleave', this.ulliMouseoUT);
    }
  },
  methods: {
    selectMenu(index, indexPath) {
      this.defaultOpeneds = indexPath;
    },
    openMenu(index, indexPath) {
      if (event.target.nodeName === 'I') {
        event.target.parentNode.nextElementSibling.classList.add('actUl');
      } else if (event.target.nodeName === 'SPAN') {
        event.target.parentNode.nextElementSibling.classList.add('actUl');
      } else if (event.target.nodeName === 'DIV') {
        event.target.parentNode.lastChild.classList.add('actUl');
      }
    },
    ulliMouseIn() {
      // event.target.classList.add('act');
      event.target.style='color: rgb(201, 201, 201);background-color: rgb(0, 72, 137) !important';
    },
    ulliMouseoUT() {
      // event.target.classList.remove('act');
      event.target.style='color: #f3f3f3;';
    },
  },
  created() {
    this.menuList = getItem('asideMenu');
  }
};
</script>

<style lang="less" scoped>
.el-menu {
  border: none;
}
/deep/ .el-menu .el-menu--inline .el-menu-item.is-active {
  background: rgba(0, 90, 171, 0.3) !important;
}
/deep/ .el-menu-item.is-active:before {
  content: '';
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #005aab;
}
/deep/.el-submenu__title i {
  color: #f3f3f3;
}
/deep/.el-menu .el-menu--inline > div {
  background-color: #000 !important;
  padding: 8px 0;
}
/deep/.el-menu .el-menu--inline > div > li {
  background-color: #000 !important;
}
</style>
<style>
.actUl {
  background-color: #000 !important;
}
.actUl > div {
  background-color: #000 !important;
  padding: 8px 0;
}
.actUlli {
  background-color: #000 !important;
}
.act {
  background-color: rgb(0, 72, 137) !important;
}
</style>
