import BasicLayout from '@/views/layout/BasicLayout.vue';

const occupationalOARoutes = {
  path: '/OAlist',
  name: 'OAlist',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'OA',
    code: 'OA-leader-task',	
    title: '领导交办事项',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/OA-leader-task-list',
      name: 'OALeaderTaskList',
      component: () => import('@/views/pages/general-user/OA-manage/leaders-task'),
      meta: {
        roleType: 'OA',
        code: 'OA-leader-task-list',
        title: '领导交办事项台账',
        menu: true
      }
    },
    {
      path: '/OA-task-todo',
      name: 'OATaskTodo',
      component: () => import('@/views/pages/general-user/OA-manage/task-todo'),
      meta: {
        roleType: 'OA',
        code: 'OA-task-todo',
        title: '待办事项',
        menu: true
      }
    },
    {
      path: '/OA-task-done',
      name: 'OATaskDone',
      component: () => import('@/views/pages/general-user/OA-manage/task-done'),
      meta: {
        roleType: 'OA',
        code: 'OA-task-done',
        title: '已办事项',
        menu: true
      }
    },
  ],
};

export default occupationalOARoutes;
