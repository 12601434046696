/*
 * @Author: your name
 * @Date: 2021-11-08 11:07:23
 * @LastEditTime: 2021-11-22 10:33:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\router\super-admin\4.sys-setting.js
 */
import BasicLayout from '@/views/layout/BasicLayout.vue';
import sysSettingBasicLayout from '@/views/layout/sys-setting/BasicLayout.vue';
const sysSettingRoute = {
  path: '/sys-setting',
  name: 'SysSetting',
  component: BasicLayout,
  meta: {
    roleType: 'super',
    title: '系统配置',
    menu: true,
    icon: 'icon-caidan1-3xitongpeizhi'
  },
  children: [
    {
      path: '/sys-setting',
      name: 'SystemSetting',
      component: sysSettingBasicLayout,
      meta: {
        roleType: 'super',
        title: '系统配置',
        menu: false
      },
      children: [
        {
          path: '/sys-setting', //内页资料配置
          name: 'DataSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/data-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_hidden-setting', //隐患配置
          name: 'HiddenSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/hidden-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_safety-setting',
          name: 'safetySetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/safety-setting'),

          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_hazard-setting', //危险源配置
          name: 'HazardSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/hazard-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_meet-setting',
          name: 'meetSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/meet-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_score-setting', //风险点配置
          name: 'ScoreSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/score-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_risk-setting', //风险点配置
          name: 'RiskSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/risk-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/security_performance-setting', //履职岗位配置
          name: 'PerformanceSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/security-config/performance-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/env_occupational-setting', //环保档案配置
          name: 'envOccupationaSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/env-config/archives-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/env_hidden-setting', //环保隐患配置
          name: 'envHiddenSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/env-config/hidden-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/health_occupational-setting', //职业卫生档案配置
          name: 'healthOccupationalSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/health-config/archives-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        },
        {
          path: '/health_hideen-setting', //职业健康管理-隐患配置
          name: 'healthHideenSetting',
          component: () => import('@/views/pages/super-admin/sys-setting/health-config/hidden-setting'),
          meta: {
            roleType: 'super',
            title: '系统配置',
            menu: false
          }
        }
      ]
    }
  ]
};
export default sysSettingRoute;
