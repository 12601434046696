import screenHeader from '@/views/layout/screen/Header.vue';

const screenPageRoutes = {
  path: '/screen-page',
  name: 'screenPage',
  component: screenHeader,
  redirect: '/tunnel-monitoring',
  meta: {
    roleType: 'security',
    code: 'screen-view',
    title: '大屏展示',
    menu: false,
    icon: ''
  },
  children: [
    {
      path: '/tunnel-monitoring',
      name: 'tunnel-monitoring',
      component: () => import('@/views/pages/general-user/security-pages/srceen/tunnel-monitoring'),
      meta: {
        roleType: 'security',
        code: 'tunnel-monitoring',
        title: '隧道监控大屏',
        menu: false
      }
    },
    {
      path: '/emergency-supplies',
      name: 'emergency-supplies',
      component: () => import('@/views/pages/general-user/security-pages/srceen/emergency-supplies'),
      meta: {
        roleType: 'security',
        code: 'emergency-supplies',
        title: ' 应急物资调度大屏',
        menu: false
      }
    }
  ]
};

export default screenPageRoutes;
