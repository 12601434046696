<template lang="">
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="培训内容">
        <el-input v-model="pages.trainContent" placeholder="请输入培训内容" clearable style="width:250px"></el-input>
      </el-form-item>
      <el-form-item label="培训时间">
        <el-date-picker
          v-model="trainDate"
          type="daterange"
          clearable
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="组织单位">
        <el-input v-model="pages.organizationalUnit" placeholder="请输入组织单位" clearable style="width:250px"></el-input>
        </el-select>
      </el-form-item>
      <el-form-item label="所属机构">
        <el-select v-model="pages.orgIdList" placeholder="请选择所属机构" clearable multiple collapse-tags>
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <div>
      <el-button v-if="$check('health-train_add')" type="primary" @click="addInfo" icon="el-icon-plus">新增培训记录</el-button>
      <el-button
      v-if="$check('health-train_export')"
        class="exportbtn"
        :disabled="!isChoose"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        @click="exportData"
        >导出</el-button
      >
    </div>
    <!-- 显示列表 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      stripe
      style="width: 100%"
      v-loading="tabelLoading"
      @selection-change="handleSelectionChange"
      max-height="550"
    >
      <el-table-column type="selection" width="55" align="center" fixed> </el-table-column>
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="orgName" label="所属机构" align="center" width="180" fixed sortable> </el-table-column>
      <el-table-column prop="trainDate" label="培训时间" align="center" width="150" sortable> </el-table-column>
      <el-table-column prop="personNum" label="培训人数" align="center" width="150" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="trainContent" label="培训内容" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="trainPerson" label="培训人" align="center" width="150" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="organizationalUnit" label="组织单位" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check('health-train_edit')" class="action-btn" @click="handledetails(scope.row)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.current"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <list-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :info="dialogData"
      @handel-success="handelSuccess"
    ></list-dialog>
  </div>
</template>
<script>
import { getTrainList,exportTrain } from '@/api/general-user/health/health-train';
import { getorgList } from '@/api/general-user/health/dIsease/health';
import listDialog from './listDialog.vue';
import { cloneDeep } from 'lodash';
import { exportFun } from '@/utils/export.js';
export default {
  components: { listDialog },
  data() {
    return {
      /* 机构数 */
      orgList: [],
      /* 数据 */
      tableData:[],
      total:0,
      tabelLoading: false,
      /* 分页、搜索栏 */
      pages: {
        size: 10,
        current: 1,
        trainContent: '',
        orgIdList: [],
        organizationalUnit: '',
        searchBeginTime:"",
        searchEndTime:"",
      },
      trainDate: [],
      /* 回显数据 */
      dialogVisible: false,
      dialogTitle: '',
      dialogData: {},
      /* 导出按钮状态 */
      isChoose: false,
      exportLoading: false,
      /* 导出数据ID */
      ids: [],
      /* 限制时间到今天 */
      pickerOptions:{
        disabledDate(time) {
            return time.getTime() > Date.now();
          },
      },
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.current = 1;
      this.pages.size = 10;
      this.getTrainList();
    },
    /* 获取数据列表 */
    getTrainList() {
      this.tabelLoading = true;
      getTrainList(this.pages).then(res => {
          this.tabelLoading = false;
          this.tableData = res.data.records;
          this.total=res.data.total
      });
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.current = 1;//重置为第一页
      this.pages.size = val;
      this.getTrainList();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.current = val;
      this.getTrainList();
    },
    /* 重置搜索框 */
    resetInput() {
      this.$refs.multipleTable.clearSort();
      this.trainDate=[];
      this.pages.searchBeginTime="";
      this.pages.searchEndTime="";
      this.pages.trainContent='';
      this.pages.orgIdList=[];
      this.pages.organizationalUnit='';
      this.pages.current=1;
      this.pages.size=10;
      this.getTrainList();
    },
    /* 弹出框显隐 */
    DialogShow() {
      this.dialogVisible = true;
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增';
      this.$refs.formRef.isRead = false;
      this.DialogShow();
    },
    /* 查看详情 */
    handledetails(row) {
      this.dialogTitle = '查看详情';
      this.$refs.formRef.isRead = true;
      this.DialogShow();
      const obj = cloneDeep(row);
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 导出勾选框获取ID */
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.isChoose = Boolean(this.ids.length);
    },
    /* 获取机构列表 */
    getorgList() {
      getorgList().then(res => {
          this.orgList = res.data;
      });
    },
    /* 操作回调 */
    handelSuccess(arr) {
      //arr[0]为返回列表第一页，否则为编辑，在当前页
      //arr[1]为是否是删除操作
      if (arr[0]) {
        this.pages.current = 1;
      }
      if (arr[1]) {
        if(this.tableData.length===1&&this.pages.current>1){
          this.pages.current=this.pages.current-1;//删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.getTrainList();
      //刷新列表
    },
    /* 导出数据 */
    exportData() {
      this.exportLoading = true;
      exportTrain({ idList:this.ids })
      .then(res => {
        if (res.status===200) {
          exportFun(res);
          this.exportLoading = false;
          this.$message({
            type: 'success',
            message: '下载数据成功',
          })
            this.$refs.multipleTable.clearSelection();
        }
        }).catch(() => {
            this.exportLoading = false;
            this.$refs.multipleTable.clearSelection();
          })
    },
  },
  created() {
    this.getTrainList();
    this.getorgList();
  },
  watch:{
    trainDate(val){
      this.pages.searchBeginTime=val[0];
      this.pages.searchEndTime=val[1];
    },
  },
};
</script>
<style lang="less" scoped>
.btn {
  margin-left: 50px;
}
.exportbtn {
  float: right;
}
</style>
