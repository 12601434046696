import Equipment from '@/views/pages/general-user/health-pages/protective-equipment';
import BasicLayout from '@/views/layout/BasicLayout.vue';

const EquipmentRoutes = {
  path: '/protective-equipment',
  name: 'ProtectiveEquipment',
  component: BasicLayout,
  redirect: '/protective-equipmentlist',
  meta: {
    roleType: 'health',
    code: 'health-disease-monitoring',
    title: '防护用品领用发放记录',
    menu: true,
    icon: 'icon-zcaidan1-2fanghuyongpinlingyongfafangjilu'
  },
  children: [{
    path: '/protective-equipment',
    name: 'EquipmentList',
    component: Equipment,
    meta: {
      roleType: 'health',
      code: 'protective-equipment-list',
      title: '防护用品领用发放记录列表',
      menu: false
    }
  }]
};

export default EquipmentRoutes;
