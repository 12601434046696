import BasicLayout from '@/views/layout/BasicLayout.vue';

const FacilityEnvRoutes = {
  path: '/env-protective-facility',
  name: 'EnvProtectiveFacility',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-protective-facility',
    title: '环保设施',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-protective-facility-manage',
      name: 'EnvProtectiveFacilityManage',
      component: () => import('@/views/pages/general-user/env-pages/protective-facility'),
      meta: {
        roleType: 'environment',
        code: 'env-protective-facility-manage',
        title: '环保设施管理',
        menu: true
      }
    }
  ]
};

export default FacilityEnvRoutes;
