import BasicLayout from '@/views/layout/BasicLayout.vue';

const EmergencyEnvRoutes = {
  path: '/env-emergency-rescue',
  name: 'EnvEmergencyRescue',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-emergency-rescue',
    title: '应急救援',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-emergency-drill',
      name: 'EnvEmergencyDrill',
      component: () => import('@/views/pages/general-user/env-pages/emergency-drill'),
      meta: {
        roleType: 'environment',
        code: 'env-emergency-drill',
        title: '应急演练',
        menu: true
      }
    }
  ]
};

export default EmergencyEnvRoutes;
