import BasicLayout from '@/views/layout/BasicLayout.vue';

const menuMgrRoutes = {
  path: '/menu-mgr',
  name: 'menuMgr',
  component: BasicLayout,
  redirect: '/menu-mgr',
  meta: {
    roleType: 'super',
    title: '菜单管理',
    menu: true,
    icon: 'iconfont icon-caidan1-4caidanguanli'
  },
  children: [
    {
      path: '/menu-mgr',
      name: 'menuMgrIndex',
      component: () => import('@/views/pages/super-admin/menu-mgr/index.vue'),
      meta: {
        roleType: 'super',
        title: '菜单管理',
        menu: false
      }
    }
  ]
};

export default menuMgrRoutes;
