import request from '@/utils/request.js';
/**
 * 获取文件夹列表
 * @returns { Promise }
 */
export function getOrgList() {
  return request({
    url: '/traffic-construction/org/getOrgList',
    method: 'get'
  });
};

/**
 * 获取文件夹列表
 * @returns { Promise }
 */
export function getFolderList(data) {
  return request({
    url: '/traffic-construction/folder/getLevelList',
    method: 'post',
    data
  });
};

/**
 * 新增/编辑文件夹
 * @returns { Promise }
 */
export function postFolder(data) {
  return request({
    url: '/traffic-construction/folder/addOrEdit',
    method: 'post',
    data
  });
};

/**
 * 删除文件夹
 * @returns { Promise }
 */
export function deleteFolder(data) {
  return request({
    url: '/traffic-construction/folder/delete',
    method: 'post',
    data
  });
};
