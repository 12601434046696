/*
 * @Author: your name
 * @Date: 2021-11-08 11:06:56
 * @LastEditTime: 2021-12-23 16:16:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\element\index.js
 */
import Vue from 'vue';
import {
  Icon,
  Button,
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItem,
  Loading,
  Upload,
  Table,
  TableColumn,
  Pagination,
  Divider,
  Row,
  Col,
  Empty,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Tree,
  Dialog,
  Cascader,
  Message,
  Switch,
  Breadcrumb,
  BreadcrumbItem,
  MessageBox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  RadioGroup,
  Radio,
  Tooltip,
  Tag,
  Notification,
  DatePicker,
  Popover,
  InputNumber,
  Timeline,
  TimelineItem,
  Card,
  Autocomplete,
  Skeleton,
  SkeletonItem,
} from 'element-ui';

Vue.prototype.$ELEMENT = { size: 'medium', zIndex: 3000 };

Vue.use(Icon);
Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Divider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Empty);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tree);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(Switch);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Tooltip);
Vue.use(Tag);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Loading.directive);
Vue.use(InputNumber);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Autocomplete);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$notify = Notification;
