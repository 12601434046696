import BasicLayout from '@/views/layout/BasicLayout.vue';

const healthsysManagementRoute = {
  path: '/health-sys-management',
  name: 'healthSysManagement',
  component: BasicLayout,
  redirect: '/health-org-management',
  meta: {
    roleType: 'health',
    code: 'sys-mgt',
    title: '系统管理',
    menu: true,
    icon: 'iconfont icon-caidan2-8xitongguanli'
  },
  children: [
    {
      path: '/health-org-management',
      name: 'healthOrgManagement',
      component: () => import('@/views/pages/general-user/health-pages/sys-management/org-management'),
      meta: {
        roleType: 'health',
        code: 'health-org-mgt-root_list',
        title: '机构管理',
        menu: true
      }
    },
    {
      path: '/health-user-management',
      name: 'healthUserManagement',
      component: () => import('@/views/pages/general-user/health-pages/sys-management/user-management'),
      meta: {
        roleType: 'health',
        code: 'health-user-mgt-root_list',
        title: '人员管理',
        menu: true
      }
    },
    {
      path: '/health-role-management',
      name: 'healthRoleManagement',
      component: () => import('@/views/pages/general-user/health-pages/sys-management/role-management'),
      meta: {
        roleType: 'health',
        code: 'health-role-mgt-root_list',
        title: '角色管理',
        menu: true
      }
    },
    {
      path: '/health-personal-center',
      name: 'healthPersonalCenter',
      component: () => import('@/views/pages/general-user/health-pages/sys-management/personal-center'),
      meta: {
        roleType: 'health',
        code: 'health-personal-center',
        title: '个人中心',
        menu: true
      }
    }
  ]
};

export default healthsysManagementRoute;
