module.exports = {
  TITLE: '交建管理',
  BASE_URL: '/api',
  APP_CODE: 'traffic-construction',
  SUCCESS_CODE: 200,
  // 平台生产环境登录页地址
  smartparkProURL: 'http://36.138.233.147:8070/#/login',
  // 平台开发环境登录页地址
  smartparkDevURL: `${window.location.protocol}//${window.location.hostname}:8090/#/login`,
  // 图片预览地址
  imgPreview: '/roses-pub-service/sysFileInfo/preview?appBucketName=traffic-construction&fileId='
};
