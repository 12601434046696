import BasicLayout from '@/views/layout/BasicLayout.vue';
const preventionControlRoute = {
  path: '/preventionControl',
  name: 'PreventionControl',
  component: BasicLayout,
  redirect: '/hazardIdentification',
  meta: {
    roleType: 'security',
    code: 'double-prevention',
    title: '双重预防',
    menu: true,
    icon: 'iconfont icon-caidan2-2shuangfangkong'
  },
  children: [
    {
      path: '/hazard-identification',
      name: 'HazardIdentification',
      component: () => import('views/pages/general-user/security-pages/prevention-control/hazard-identity'),
      meta: {
        roleType: 'security',
        code: 'double-prevention_list',
        title: '危险源辨识',
        menu: true
      }
    },
    {
      path: '/risk-control',
      name: 'RiskControl',
      component: () => import('views/pages/general-user/security-pages/prevention-control/risk-control'),
      meta: {
        roleType: 'security',
        code: 'risk-control_list',
        title: '风险分级管控',
        menu: true
      }
    },
    {
      path: '/danger-treat',
      name: 'DangerTreat',
      component: () => import('views/pages/general-user/security-pages/prevention-control/danger-treat'),
      meta: {
        roleType: 'security',
        code: 'danger-treat_list',
        title: '隐患排查治理',
        menu: true
      }
    },
    {
      path: '/construction-inspection',
      name: 'ConstructionInspection',
      component: () => import('views/pages/general-user/security-pages/prevention-control/construction-inspection'),
      meta: {
        roleType: 'security',
        code: 'construction-inspection_list',
        title: '施工安全检查',
        menu: true
      }
    }
  ]
};

export default preventionControlRoute;
