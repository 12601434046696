import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/responsibilitye-list',
  name: 'ResponsibilityList',
  component: BasicLayout,
  redirect: '/responsibility-list',
  meta: {
    roleType: 'security',
    code: 'detailed-list',
    title: '责任清单',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/responsibility-list',
      name: 'responsibilityList',
      component: () => import('@/views/pages/general-user/security-pages/responsibility-list'),
      meta: {
        roleType: 'security',
        code: 'responsibility-list',
        title: '责任清单',
        menu: true
      }
    }
  ]
};

export default homePageMgrRoutes;
