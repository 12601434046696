import BasicLayout from '@/views/layout/BasicLayout.vue';

const riskQURoutes = {
  path: '/quality-risk',
  name: 'qualityRisk',
  component: BasicLayout,
  redirect: '/quality-risk-list',
  meta: {
    roleType: 'quality',
    code: 'quality-quality-risk',	
    title: '质量隐患管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/quality-risk-list',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '质量隐患管理',
        menu: false
      }
    },
  ]
};

export default riskQURoutes;
