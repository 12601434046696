<template lang="">
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属机构" prop="orgId" :required="!isRead">
                <div class="block">
                  <el-cascader
                    v-model="ruleForm.orgId"
                    :show-all-levels="false"
                    :options="orgList"
                    :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name' }"
                    style="width:100%"
                    clearable
                    :disabled="isRead"
                    placeholder="请选择所属机构"
                  ></el-cascader>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="培训时间" prop="trainDate" :required="!isRead">
                <el-date-picker
                  v-model="ruleForm.trainDate"
                  type="date"
                  placeholder="请选择培训时间"
                  clearable
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                  style="width:100%"
                  :disabled="isRead"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="培训人数" prop="personNum" :required="!isRead">
                <el-input
                  v-model="ruleForm.personNum"
                  placeholder="请输入培训人数"
                  :maxlength="20"
                  :disabled="isRead"
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="培训内容" prop="trainContent" :required="!isRead">
                <el-input
                  v-model="ruleForm.trainContent"
                  placeholder="请输入培训内容"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="培训人" prop="trainPerson" :required="!isRead">
                <el-input v-model="ruleForm.trainPerson" :maxlength="20" placeholder="请输入培训人" :disabled="isRead">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="组织单位" prop="organizationalUnit" :required="!isRead">
                <el-input
                  v-model="ruleForm.organizationalUnit"
                  placeholder="请输入组织单位"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="附件" prop="fileIds">
                <div v-if="!isRead">
                  <upload-multiple-file
                    v-model="ruleForm.fileIds"
                    :default-list="defaultList"
                    @get-file-data="getFileData"
                  ></upload-multiple-file>
                </div>
                <div v-else>
                  <div
                    v-for="item in sysFileInfos"
                    :key="item.fileId"
                    @click="uploadFile(item.fileId)"
                    style="cursor:pointer"
                  >
                    {{ item.fileOriginName }}
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else="isRead">
          <el-button @click="handelChanel">取 消</el-button>
          <el-button type="primary" @click="handelSure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getOrgTree } from '@/api/general-user/health/dIsease/health';
import { deletehealthTrain, addhealthTrain, editTrain, getDetailTrain } from '@/api/general-user/health/health-train';
import { validateRules } from './validateRules.js';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { exportFun } from '@/utils/export.js';
import { cloneDeep } from 'lodash';

export default {
  mixins: [validateRules],
  components: { UploadMultipleFile },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {},
      /* 是否是查看详情 */
      isRead: false,
      /* 机构数 */
      orgList: [],
      /* 确认按钮状态 */
      btnLoading: false,
      defaultList: [],
      /* 自身机构 */
      selfOrg: {},
      dialogLoading: false,
      /* 时间选取今天以前 */
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      id: '',
      sysFileInfos: []
    };
  },
  methods: {
    /* 父级传递的值 */
    initFormDetail(val) {
      this.resetRuleform();
      this.ruleForm = val;
      this.id = val.id; //该数据ID
      this.getDetailTrain();
    },
    /* 下载附件 */
    uploadFile(fileId) {
      downloadFile({ fileId })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '下载数据成功'
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /* 退出重置 */
    handelClose() {
      this.defaultList = [];
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelChanel();
    },
    /* 关闭弹出框 */
    handelChanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelSure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.addhealthTrain();
          } else if (this.dialogTitle === '修改') {
            this.editTrain();
          }
        } else {
          return false;
        }
      }); //新增
    },
    /* 新增方法 */
    addhealthTrain() {
      this.btnLoading = true;
      addhealthTrain(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deletehealthTrain();
      });
    },
    /* 删除 */
    deletehealthTrain() {
      deletehealthTrain({ id: this.id }).then(res => {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, true]);
      });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改');
      this.isRead = false;
    },
    /* 修改接口调用*/
    editTrain() {
      this.resetRuleform(this.ruleForm);
      this.ruleForm.id = this.id;
      this.btnLoading = true;
      editTrain(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [false, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 重置方法 带参数则剔除多余的字段 */
    resetRuleform(val) {
      this.ruleForm = {
        orgId: val?.orgId || '',
        trainDate: val?.trainDate || '',
        personNum: val?.personNum || '',
        trainContent: val?.trainContent || '',
        trainPerson: val?.trainPerson || '',
        organizationalUnit: val?.organizationalUnit || '',
        fileIds:val?.fileIds || "",
      };
    },
    /* 获取机构数 */
    getOrgTree() {
      getOrgTree().then(res => {
          this.orgList.push(res.data[0]);
      });
    },
    /* 上传附件处理 */
    getFileData(fileIdList) {
      this.ruleForm.fileIds = fileIdList.join(',');
    },
    /* 调用详情接口 */
    getDetailTrain() {
      this.dialogLoading = true;
      getDetailTrain({ id: this.id }).then(res => {
          this.dialogLoading = false;
          this.ruleForm.fileIds=res.data.fileIds;
          this.sysFileInfos = res.data.fileInfoList;
          this.defaultList = cloneDeep(this.sysFileInfos);
          this.defaultList.forEach(item => {
            item.name = item.fileOriginName;
            item.url = item.filePath;
          });
      }).catch(() => {
          this.dialogLoading = false;
        });
    }
  },
  created() {
    this.getOrgTree();
  },
  watch: {
    dialogTitle(val) {
      if (val.includes('新增')) {
        this.resetRuleform();
      }
    },
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    }
  }
};
</script>
<style lang=""></style>
