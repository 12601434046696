<template lang="">
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="岗位名称">
        <el-input v-model="pages.post" placeholder="请输入岗位名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="危害因素">
        <el-input v-model="pages.hazards" placeholder="请输入危害因素名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="判定结论">
        <el-select v-model="pages.conclusions" placeholder="请输入判定结论" clearable>
          <el-option label="达标" value="1"></el-option>
          <el-option label="未达标" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属机构">
        <el-select v-model="pages.ids" placeholder="请选择所属机构" clearable multiple collapse-tags>
          <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <el-button v-if="$check('health-disease_add')" type="primary" @click="addInfo" icon="el-icon-plus"
      >新增职业病危害监测记录</el-button
    >
    <el-button
      v-if="$check('health-disease_export')"
      class="exportbtn"
      :disabled="!isChoose"
      v-loading.fullscreen="exportLoading"
      element-loading-text="拼命导出中"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      @click="exportData"
      >导出</el-button
    >
    <!-- 显示列表 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      stripe
      style="width: 100%"
      v-loading="tabelLoading"
      @selection-change="handleSelectionChange"
      max-height="550"
    >
      <el-table-column type="selection" width="55" align="center" fixed> </el-table-column>
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="orgName" label="所属机构" align="center" width="180" fixed sortable> </el-table-column>
      <el-table-column prop="post" label="岗位" align="center" width="150" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column prop="hazards" label="职业病危害因素" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column prop="results" label="监测结果" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column prop="conclusionContent" label="判定结论" align="center" width="150" sortable> </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check('health-disease_edit')" class="action-btn" @click="handleDetails(scope.row)"
            >查看详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <list-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :info="dialogData"
      @handel-success="handelSuccess"
    ></list-dialog>
  </div>
</template>
<script>
import { getDiseaseList, getorgList, exportDisease } from '@/api/general-user/health/dIsease/health.js';
import listDialog from './listDialog.vue';
import { exportFun } from '@/utils/export.js';
import { cloneDeep } from 'lodash';
export default {
  components: { listDialog },
  data() {
    return {
      /* 机构数 */
      orgList: [],
      /* 表格 */
      tableData: [],
      total: 0,
      tabelLoading: false,
      /* 分页、搜索栏 */
      pages: {
        pageSize: 10,
        pageNo: 1,
        post: '',
        hazards: '',
        conclusions: [],
        ids: []
      },
      /* 回显数据 */
      dialogVisible: false,
      dialogTitle: '',
      dialogData: {},
      /* 导出按钮状态 */
      isChoose: false,
      exportLoading: false,
      /* 导出数据ID */
      ids: [],
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.getDiseaseList();
    },
    /* 获取数据列表 */
    getDiseaseList() {
      this.tabelLoading = true;
      getDiseaseList(this.pages).then(res => {
          this.tabelLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
      });
    },
    /* 操作回调 */
    handelSuccess(arr) {
      //arr[0]为返回列表第一页，否则为编辑，在当前页
      //arr[1]为是否是删除操作
      if (arr[0]) {
        this.pages.pageNo = 1;
      }
      if (arr[1]) {
        if (this.tableData.length === 1 && this.pages.pageNo > 1) {
          this.pages.pageNo = this.pages.pageNo - 1; //删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.getDiseaseList();
      //刷新列表
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = val;
      this.getDiseaseList();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      this.getDiseaseList();
    },
    /* 重置搜索框 */
    resetInput() {
      this.$refs.multipleTable.clearSort();
      this.pages = {};
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = 10;
      this.getDiseaseList();
    },
    /* 弹出框显隐 */
    DialogShow() {
      this.dialogVisible = true;
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增';
      this.$refs.formRef.isRead = false;
      this.DialogShow();
    },
    /* 查看详情 */
    handleDetails(row) {
      this.dialogTitle = '查看详情';
      this.$refs.formRef.isRead = true;
      this.DialogShow();
      const obj = cloneDeep(row);
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 导出勾选框获取ID */
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.isChoose = Boolean(this.ids.length);
    },
    /* 获取机构列表 */
    getorgList() {
      getorgList().then(res => {
          this.orgList = res.data;
      });
    },
    /* 导出数据 */
    exportData() {
      this.exportLoading = true;
      exportDisease({
        ids: this.ids
      })
        .then(res => {
          if (res.status === 200) {
            exportFun(res);
            this.exportLoading = false;
            this.$message({
              type: 'success',
              message: '导出数据成功'
            });
            this.$refs.multipleTable.clearSelection();
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    }
  },
  created() {
    this.getDiseaseList();
    this.getorgList();
  }
};
</script>
<style lang="less" scoped>
.btn {
  margin-left: 50px;
}
.exportbtn {
  float: right;
}
</style>
