import BasicLayout from '@/views/layout/BasicLayout.vue';

const projectTECRoutes = {
  path: '/project-planning',
  name: 'projectPlanning',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'technical',
    code: 'quality-project-planning',	
    title: '项目总体规划',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/project-planning',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '项目总体规划',
        menu: true
      }
    },
  ]
};

export default projectTECRoutes;
