<!--
 * @Author: your name
 * @Date: 2021-11-08 11:06:56
 * @LastEditTime: 2021-12-25 16:53:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\layout\BasicLayout.vue
-->
<template>
  <div>
    <el-container class="basic-layout">
      <el-header height="64px">
        <Header />
      </el-header>
      <el-container>
        <el-aside width="240px">
          <AsideMenu />
        </el-aside>
        <el-main>
          <div :class="$route.meta.title !== '首页' ? 'card' : 'cardHome'">
            <Breadcrumb v-if="$route.meta.title !== '首页'" />
            <div class="card-title" v-if="$route.meta.title !== '首页'">{{ $route.meta.title || '' }}</div>
            <div :style="{ padding: $route.meta.title !== '首页' ? '16px' : '0' }">
              <router-view></router-view>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from './Header.vue';
import AsideMenu from './AsideMenu.vue';
import Breadcrumb from './Breadcrumb';
export default {
  components: { Header, AsideMenu, Breadcrumb }
};
</script>

<style lang="less" scoped>
.basic-layout {
  height: 100vh;
  .el-header {
    background-color: #005aab;
    background-image: url('~assets/images/header/header.png');
  }
  .el-aside {
    background-color: #005aab;
  }
  /deep/ .el-main {
    padding: 16px;
    background-color: #f3f6fe;
    .card {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      // min-width: 1160px;
      .card-title {
        height: 48px;
        line-height: 48px;
        padding: 0 16px;
        font-weight: 700;
        color: #303133;
        border-bottom: 1px solid #dcdfe7;
      }
    }
    .cardHome {
      height: 100%;
    }
  }
}
</style>
