import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './element/index.js';
import './permission.js';
/* css reset */
import 'normalize.css/normalize.css';
import '@/assets/style/common.css';
import '@/assets/style/element.css';
import '@/assets/style/video-js.css';
/* 百度地图 */
import BaiduMap from 'vue-baidu-map'
/* 按钮权限控制 */
import checkPermission from '@/utils/checkPermission.js';
import videojs from "video.js";
Vue.prototype.$video = videojs;
Vue.prototype.$check = checkPermission;

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'QDMI2VFMUoHL8YE2s8OjLubuts3WTrui'
})
/* import onlyNumber from '@/directive/input'; //添加此行=>自定义全局指令
Vue.use(onlyNumber); //添加此行=>使用该全局指令 */

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
