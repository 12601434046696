import BasicLayout from '@/views/layout/BasicLayout.vue';

const enterpriseUserMgrRoutes = {
  path: '/user-mgr',
  name: 'enterpriseUserMgr',
  component: BasicLayout,
  redirect: '/user-mgr',
  meta: {
    roleType: 'super',
    title: '企业用户管理',
    menu: true,
    icon: 'icon-caidan1-2qiyeyonghuguanli'
  },
  children: [
    {
      path: '/user-mgr',
      name: 'enterpriseUserMgrIndex',
      component: () => import('@/views/pages/super-admin/enterprise-user-mgr/index.vue'),
      meta: {
        roleType: 'super',
        title: '企业用户管理',
        menu: false
      }
    }
  ]
};

export default enterpriseUserMgrRoutes;
