<template lang="">
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="form-box"
        v-loading="dialogLoading"
      >
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属项目部" prop="projectId" :required="!isRead">
                <div class="block">
                  <el-select
                    v-model="ruleForm.projectId"
                    style="width:300px"
                    clearable
                    placeholder="请输入所属项目部"
                    :disabled="isRead"
                  >
                  <el-option 
                    v-for="item in orgList" 
                    :key="item.orgId"
                    :label="item.orgName" 
                    :value="item.orgId"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="接触职业病危害因素" prop="hazards" :required="!isRead">
                <el-input
                  v-model="ruleForm.hazards"
                  :maxlength="50"
                  placeholder="请输入接触职业病危害因素"
                  clearable
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="个人防护用品名称" prop="material" :required="!isRead">
                <el-input
                  v-model="ruleForm.material"
                  placeholder="请输入个人防护用品名称"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="型号" prop="model" :required="!isRead">
                <el-input
                  v-model="ruleForm.model"
                  placeholder="请输入型号"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="数量" prop="num" :required="!isRead">
                <el-input
                  v-model="ruleForm.num"
                  :maxlength="50"
                  placeholder="请输入数量"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="领取人" prop="recipients" :required="!isRead">
                <el-input
                  v-model="ruleForm.recipients"
                  placeholder="请输入领取人"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领取日期" prop="collectionDate" :required="!isRead">
                <el-date-picker
                  v-model="ruleForm.collectionDate"
                  type="date"
                  placeholder="请选择领取日期"
                  clearable
                  :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd"
                  style="width:300px"
                  :disabled="isRead"
                >
                </el-date-picker>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="ruleForm.remark"
                  placeholder="请输入备注"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                  :row="2"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else>
          <el-button @click="handelChanel">取 消</el-button>
          <el-button type="primary" @click="handelSure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addMaterial, deleteMaterial, editMaterial,getDataRangByNature } from '@/api/general-user/health/protective-equipment';
import { validateRules } from './validateRules.js';

export default {
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {
        projectId: '',
        hazards: '',
        material: '',
        model: '',
        num: '',
        recipients: '',
        collectionDate: '',
        remark: ''
      },
      /* 是否是查看详情 */
      isRead: false,
      /* 机构数 */
      orgList: [],
      selfOrg: {},
      /* 确认按钮状态 */
      btnLoading: false,
      defaultList: [],
      infoId: '',
      dialogLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  methods: {
    /* 退出重置 */
    handelClose() {
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelChanel();
    },
    /* 关闭弹出框 */
    handelChanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelSure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.addMaterial();
          } else if (this.dialogTitle === '修改') {
            this.editMaterial();
          }
        } else {
          return false;
        }
      });
    },
    /* 新增方法 */
    addMaterial() {
      this.btnLoading = true;
      addMaterial(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [true,false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改');
      this.isRead = false;
    },
    /* 修改接口 */
    editMaterial() {
      this.btnLoading = true;
      this.ruleForm.id = this.infoId;
      editMaterial(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [false,false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 删除提醒 */
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteMaterial();
      });
    },
    deleteMaterial() {
      deleteMaterial({ id: this.infoId }).then(res => {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, true]);
      });
    },
    /* 重置方法 */
    resetRuleform() {
      this.ruleForm = {
        projectId: '',
        hazards: '',
        material: '',
        model: '',
        num: '',
        recipients: '',
        collectionDate: '',
        remark: ''
      };
      this.infoId ="";
    },
    /* 拷贝父级传递的值 */
    initFormDetail(val) {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.resetRuleform();
      /*值  */
      var {projectId,hazards,material,model,num,recipients,collectionDate,remark}=val
      this.ruleForm.projectId=projectId;
      this.ruleForm.hazards=hazards;
      this.ruleForm.material=material;
      this.ruleForm.model=model;
      this.ruleForm.num=num;
      this.ruleForm.recipients=recipients;
      this.ruleForm.collectionDate=collectionDate;
      this.ruleForm.remark=remark;
      this.infoId = val.id;//该记录ID
    },
    /* 获取所属项目部 */
    getDataRangByNature() {
      getDataRangByNature().then(res => {
          this.orgList=res.data;
      });
    },
  },
  created() {
    this.getDataRangByNature();
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    }
  }
};
</script>
<style lang="" >
</style>
