import BasicLayout from '@/views/layout/BasicLayout.vue';

const occupationalHealthRoutes = {
  path: '/occupational-health',
  name: 'OccupationalHealth',
  component: BasicLayout,
  redirect: '/health-archives',
  meta: {
    roleType: 'health',
    code: 'response',
    title: '职业卫生档案',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/health-archives',
      name: 'HealthArchives',
      component: () => import('@/views/pages/general-user/health-pages/internal-data/data-list'),
      meta: {
        roleType: 'health',
        code: 'health-archives_list',
        title: '职业卫生档案',
        menu: true
      }
    },
    {
      path: '/health-log',
      name: 'HealthLog',
      component: () => import('@/views/pages/general-user/health-pages/internal-data/action-log'),
      meta: {
        roleType: 'health',
        code: 'health_log_list',
        title: '操作日志',
        menu: true
      }
    }
  ]
};

export default occupationalHealthRoutes;
