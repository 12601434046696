<template lang="">
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="form-box"
        v-loading="dialogLoading"
      >
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属机构" prop="orgId" :required="!isRead">
                <div class="block">
                  <el-cascader
                    v-model="ruleForm.orgId"
                    :show-all-levels="false"
                    :options="orgList"
                    :props="{ checkStrictly: true, emitPath: false, value: 'id', label: 'name' }"
                    style="width:320px"
                    clearable
                    placeholder="请输入所属机构"
                    :disabled="isRead"
                  ></el-cascader>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="岗位" prop="post" :required="!isRead">
                <el-input
                  v-model="ruleForm.post"
                  :maxlength="20"
                  placeholder="请输入岗位"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="职业病危害因素" prop="hazards" :required="!isRead">
                <el-input
                  v-model="ruleForm.hazards"
                  placeholder="请输入职业病危害因素"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="接触时长" prop="contactTime" :required="!isRead">
                <el-input
                  v-model="ruleForm.contactTime"
                  placeholder="请输入接触时长"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计算结果最大值" prop="maxResult" :required="!isRead">
                <el-input
                  v-model="ruleForm.maxResult"
                  :maxlength="20"
                  placeholder="请输入计算结果最大值"
                  :disabled="isRead"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="监测结果" prop="results" :required="!isRead">
                <el-input
                  v-model="ruleForm.results"
                  placeholder="请输入监测结果"
                  :maxlength="50"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="判定结论" prop="conclusion" :required="!isRead">
                <el-select
                  v-model="ruleForm.conclusion"
                  placeholder="请选择判定结论"
                  style="width:320px"
                  :disabled="isRead"
                >
                  <el-option label="达标" value="1"></el-option>
                  <el-option label="未达标" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="附件" prop="fileIds">
                <div v-if="!isRead">
                  <upload-multiple-file
                    v-model="ruleForm.fileIds"
                    :default-list="defaultList"
                    @get-file-data="getFileData"
                  ></upload-multiple-file>
                </div>
                <div v-else>
                  <div
                    v-for="item in sysFileInfos"
                    :key="item.fileId"
                    @click="uploadFile(item.fileId)"
                    style="cursor:pointer"
                  >
                    {{ item.fileOriginName }}
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else>
          <el-button @click="handelChanel">取 消</el-button>
          <el-button type="primary" @click="handelSure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { addDisease, deleteDisease, editDisease, getOrgTree } from '@/api/general-user/health/dIsease/health.js';
import { validateRules } from './validateRules.js';
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { exportFun } from '@/utils/export.js';

export default {
  mixins: [validateRules],
  components: { UploadMultipleFile },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {
        orgId: '',
        post: '',
        hazards: '',
        contactTime: '',
        maxResult: '',
        results: '',
        conclusion: '',
        fileIds: ''
      },
      /* 是否是查看详情 */
      isRead: false,
      /* 机构数 */
      orgList: [],
      selfOrg: {},
      /* 确认按钮状态 */
      btnLoading: false,
      defaultList: [],
      infoId: '',
      dialogLoading: false,
      sysFileInfos: []
    };
  },
  methods: {
    /* 退出重置 */
    handelClose() {
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelChanel();
    },
    /* 关闭弹出框 */
    handelChanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelSure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新增') {
            this.addDisease();
          } else if (this.dialogTitle === '修改') {
            this.editDisease();
          }
        } else {
          return false;
        }
      });
    },
    /* 新增方法 */
    addDisease() {
      this.btnLoading = true;
      addDisease(this.ruleForm)
        .then(res => {
          this.btnLoading = false;
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改');
      this.isRead = false;
    },
    /* 修改接口 */
    editDisease() {
      this.btnLoading = true;
      this.ruleForm.id = this.infoId;
      editDisease(this.ruleForm)
        .then(res => {
          this.btnLoading = false;
          this.$message.success('修改成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 删除提醒 */
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteDisease();
      });
    },
    /* 删除接口 */
    deleteDisease() {
      deleteDisease({ id: this.infoId }).then(res => {
        this.$message.success('删除成功');
        this.$emit('update:dialogVisible', false);
        this.$emit('handel-success', [false, true]); //为删除时回传
      });
    },
    /* 重置方法 */
    resetRuleform() {
      this.defaultList = [];
      this.sysFileInfos = [];
      this.ruleForm = {
        
      };
    },
    /* 拷贝父级传递的值 */
    initFormDetail(val) {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.resetRuleform();
      /* 值 */
      var { orgId, post, hazards, contactTime, maxResult, results, conclusion, fileIds }=val;
      this.ruleForm.orgId=orgId;
      this.ruleForm.post=post;
      this.ruleForm.hazards=hazards;
      this.ruleForm.contactTime=contactTime;
      this.ruleForm.maxResult=maxResult;
      this.ruleForm.results=results;
      this.ruleForm.conclusion=conclusion;
      this.ruleForm.fileIds=fileIds;
      this.infoId = val.id; //该记录ID
      /* 获取附件 */
      this.sysFileInfos = val.sysFileInfos;
      this.defaultList = cloneDeep(this.sysFileInfos);
      this.defaultList.forEach(item => {
        item.name = item.fileOriginName;
        item.url = item.filePath;
      });
      /* 字段为string */
      this.ruleForm.conclusion = this.ruleForm.conclusion + '';
    },
    /* 获取机构树 */
    getOrgTree() {
      getOrgTree().then(res => {
          this.orgList.push(res.data[0]);
      });
    },
    /* 上传附件处理 */
    getFileData(fileIdList) {
      this.ruleForm.fileIds = fileIdList.join(',');
    },
    /* 下载附件 */
    uploadFile(fileId) {
      downloadFile({ fileId })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '下载数据成功'
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    }
  },
  created() {
    this.getOrgTree();
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    }
  }
};
</script>
<style lang=""></style>
