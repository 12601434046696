<template>
  <div>
    <dynamic-switching @switch-display="switchDisplay"> </dynamic-switching>
    <transition mode="out-in" name="component-transition">
      <component :is="isDisplay"></component>
    </transition>
  </div>
</template>
<script>
import DynamicSwitching from 'components/DynamicSwitching';
import ChartDisplay from './chart-display';
import ListDisplay from './list-display';
export default {
  components: {
    DynamicSwitching,
    ChartDisplay,
    ListDisplay
  },
  data() {
    return {
      // 默认图表展示
      isDisplay: 'chart-display'
    };
  },
  methods: {
    switchDisplay(text) {
      //不用动态组件的话可以打印这个text的值，text的值是写死的(chart-display图表展示，list-display列表展示)
      this.isDisplay = text;
    }
  }
};
</script>
