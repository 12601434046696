import BasicLayout from '@/views/layout/BasicLayout.vue';

const SourceEnvRoutes = {
  path: '/env-source-manage',
  name: 'EnvSourceManage',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-source-manage',
    title: '污染源管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-source-manage-view',
      name: 'EnvSourceManageView',
      component: () => import('@/views/pages/general-user/env-pages/source-manage'),
      meta: {
        roleType: 'environment',
        code: 'env-source-manage-view',
        title: '污染源管理',
        menu: true
      }
    }
  ]
};

export default SourceEnvRoutes;
