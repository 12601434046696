import request from '@/utils/request.js';
/**
 * 职业卫生培训列表
 * @returns {Promise}
 */
export function getTrainList(data) {
  return request({
    url: '/traffic-construction/healthTrain/list',
    method: 'post',
    data
  });
}
/**
 * 获取自身所在机构信息
 * @returns {Promise}
 */
export function getUserOrgInfo(params) {
  return request({
    url: '/traffic-construction/getUserOrgInfo',
    method: 'get',
    params
  });
}
/**
 * 删除列表信息
 * @returns {Promise}
 */
export function deletehealthTrain(data) {
  return request({
    url: '/traffic-construction/healthTrain/delete',
    method: 'post',
    data
  });
}
/**
 * 新增列表信息
 * @returns {Promise}
 */
export function addhealthTrain(data) {
  return request({
    url: '/traffic-construction/healthTrain/add',
    method: 'post',
    data
  });
}
/**
 * 导出 列表信息
 * @returns {Promise}
 */
export function exportTrain(data) {
  return request({
    url: '/traffic-construction/healthTrain/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
/**
 * 修改列表信息
 * @returns {Promise}
 */
 export function editTrain(data) {
  return request({
    url: '/traffic-construction/healthTrain/edit',
    method: 'post',
    data
  });
}
/**
 * 详情列表信息
 * @returns {Promise}
 */
export function getDetailTrain(params) {
  return request({
    url: '/traffic-construction/healthTrain/getDetail',
    method: 'get',
    params
  });
}