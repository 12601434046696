import BasicLayout from '@/views/layout/BasicLayout.vue';

const studySCRoutes = {
  path: '/study-section',
  name: 'studySection',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'scientific',
    code: 'scientific-study-section',	
    title: '学习园地',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/study-section',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '学习园地',
        menu: false
      }
    },
  ]
};

export default studySCRoutes;
