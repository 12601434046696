/*
 * @Author: your name
 * @Date: 2021-11-09 10:25:44
 * @LastEditTime: 2021-12-07 14:22:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\router\general-uesr\3.visualization.js
 */
import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/emergency-rescue',
  name: 'emergencyRescue',
  component: BasicLayout,
  redirect: '/emergency-rescue',
  meta: {
    roleType: 'security',
    code: 'emergency-rescue',
    title: '应急救援',
    menu: true,
    icon: 'iconfont icon-caidan2-7yingjijiuyuan'
  },
  children: [
    {
      path: '/emergency-drill',
      name: 'emergencyDrill',
      component: () => import('@/views/pages/general-user/security-pages/emergency-rescue/safety-train'),
      meta: {
        roleType: 'security',
        code: 'emergency-drill_list',
        title: '应急演练',
        menu: true
      }
    },
    {
      path: '/emergency-material',
      name: 'emergencyMaterial',
      component: () => import('@/views/pages/general-user/security-pages/emergency-rescue/emergency-material'),
      meta: {
        roleType: 'security',
        code: 'emergency-material_list',
        title: '应急物资',
        menu: true
      }
    }
  ]
};

export default homePageMgrRoutes;
