import BasicLayout from '@/views/layout/BasicLayout.vue';

const enterpriseMgrRoutes = {
  path: '/enterprise-mgr',
  name: 'enterpriseMgr',
  component: BasicLayout,
  redirect: '/index',
  meta: {
    roleType: 'super',
    title: '入驻企业管理',
    menu: true,
    icon: 'icon-caidan1-1ruzhuqiyeguanli'
  },
  children: [
    {
      path: '/enterprise-mgr',
      name: 'enterpriseMgrIndex',
      component: () => import('@/views/pages/super-admin/enterprise-mgr/index.vue'),
      meta: {
        roleType: 'super',
        title: '入驻企业管理',
        menu: false
      }
    }
  ]
};

export default enterpriseMgrRoutes;
