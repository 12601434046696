import BasicLayout from '@/views/layout/BasicLayout.vue';

const physicalExaminationRoutes = {
  path: '/health-danger-manage',
  name: 'healthDangerManage',
  component: BasicLayout,
  redirect: '/physical-examination',
  meta: {
    roleType: 'health',
    code: 'hidden-danger_list',
    title: '隐患管理',
    menu: true,
    icon: 'icon-zcaidan1-3yinhuanguanli'
  },
  children: [
    {
      path: '/health-danger-manage',
      name: 'healthDangerManage',
      component: () => import('@/views/pages/general-user/health-pages/hidden-danger-manage'),
      meta: {
        roleType: 'health',
        code: 'health-hidden-danger_list',
        title: '隐患管理',
        menu: false
      }
    }
  ]
};

export default physicalExaminationRoutes;
