import BasicLayout from '@/views/layout/BasicLayout.vue';

const FundEnvRoutes = {
  path: '/env-protection-input',
  name: 'EnvProtectionInput',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-protection-input',
    title: '环保投入',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-fund-plan',
      name: 'EnvFundPlan',
      component: () => import('@/views/pages/general-user/env-pages/funds/funds-plan'),
      meta: {
        roleType: 'environment',
        code: 'env-fund-plan',
        title: '环保经费计划台账',
        menu: true
      }
    },
    {
      path: '/env-funds-use',
      name: 'EnvFundsUse',
      component: () => import('@/views/pages/general-user/env-pages/funds/funds-user'),
      meta: {
        roleType: 'environment',
        code: 'env-funds-use',
        title: '环保经费使用台帐',
        menu: true
      }
    }
  ]
};

export default FundEnvRoutes;
