import Vue from 'vue';
import VueRouter from 'vue-router';
// import getAsyncRoutes from './getAsyncRoutes.js';

import generalSafeRoutes from './general-uesr/security-router/index.js'; // 用户级路由(安全)
import generalHealthRoutes from './general-uesr/health-router/index.js'; // 用户级路由(职业健康)
import generalEnvRoutes from './general-uesr/env-router/index.js'; // 用户级路由(职业健康)
import generalOARoutes from './general-uesr/OA-router/index.js'; // 用户级路由(协同办公)
import generalTECRoutes from './general-uesr/technical-manage/index.js'; // 用户级路由(技术管理)
import generalQURoutes from './general-uesr/quality-manage/index.js'; // 用户级路由(质量管理)
import generalSCRoutes from './general-uesr/scientific-manege/index.js'; // 用户级路由(科研管理)
import superRoutes from './super-admin/index.js'; // 子系统超管路由
//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const commonRoutes = [
  {
    path: '/',
    redirect: '/login',
    meta: {
      menu: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/common/login.vue'),
    meta: {
      menu: false
    }
  }
];

const routes = [...commonRoutes, ...generalSafeRoutes, ...generalHealthRoutes,...generalEnvRoutes,...generalOARoutes,...generalTECRoutes,...generalQURoutes,...generalSCRoutes, ...superRoutes];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;

// getAsyncRoutes();
