import request from '@/utils/request.js';
/**
 * 防护用品列表
 * @returns {Promise}
 */
export function getMaterial(data) {
  return request({
    url: '/traffic-construction/material/getHealthMaterialRecordPage',
    method: 'post',
    data
  });
}
/**
 * 防护用品新增
 * @returns {Promise}
 */
export function addMaterial(data) {
  return request({
    url: '/traffic-construction/material/addHealthMaterialRecord',
    method: 'post',
    data
  });
}
/**
 * 防护用品修改
 * @returns {Promise}
 */
export function editMaterial(data) {
  return request({
    url: '/traffic-construction/material/editHealthMaterialRecord',
    method: 'post',
    data
  });
}
/**
 * 防护用品删除
 * @returns {Promise}
 */
export function deleteMaterial(data) {
  return request({
    url: '/traffic-construction/material/deleteHealthMaterialRecord',
    method: 'post',
    data
  });
}
/**
 * 防护用品导出
 * @returns {Promise}
 */
export function exportMaterial(data) {
  return request({
    url: '/traffic-construction/material/exportHealthMaterialRecord',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
/* 机构列表
 * @returns {Promise}
 */
export function getorgList(params) {
  return request({
    url: '/traffic-construction/getRangOrg',
    method: 'get',
    params
  });
}
/* 当前项目部
 * @returns {Promise}
 */
export function getDataRangByNature(params) {
  return request({
    url: '/traffic-construction/getDataRangByNature',
    method: 'get',
    params
  });
}
