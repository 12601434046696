<template>
  <el-submenu :index="parentItem.path">
    <template slot="title">
      <i class="iconfont" :class="parentItem.meta.icon" style="margin-right: 6px;color:#fff;"></i>
      <span style="">{{ parentItem.meta.title }}</span>
    </template>
    <div v-for="item in parentItem.children" :key="item.path">
      <aside-menu-item v-if="item.children && item.children.length" :parentItem="item"></aside-menu-item>
      <el-menu-item v-else :index="item.path">{{ item.meta.title}}</el-menu-item>
    </div>
  </el-submenu>
</template>

<script>
export default {
  name: 'AsideMenuItem',
  props: {
    parentItem: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style lang="less" scoped>
</style>
