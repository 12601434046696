import { render, staticRenderFns } from "./App.vue?vue&type=template&id=7f645e8d&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=7f645e8d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f645e8d",
  null
  
)

export default component.exports