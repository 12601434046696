import DiseaseMonitoring from '@/views/pages/general-user/health-pages/health-disease-monitoring';
import BasicLayout from '@/views/layout/BasicLayout.vue';

const DiseaseMonitoringRoutes = {
    path: '/health-disease-monitoring',
    name: 'HealthDiseaseMonitoring',
    component: BasicLayout,
    redirect: '',
    meta: {
      roleType: 'health',
      code: 'health-disease-monitoring',
      title: '职业病危害监测',
      menu: true,
      icon: 'icon-zcaidan1-1zhiyebingweihaijiance'
    },
    children: [
        {
          path: '/health-disease-monitoring',
          name: 'DiseaseList',
          component: DiseaseMonitoring,
          meta: {
            roleType: 'health',
            code: 'health-disease-list',
            title: '职业病危害监测列表',
            menu: false
          }
        }
      ]
};

export default DiseaseMonitoringRoutes;
