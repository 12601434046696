import BasicLayout from '@/views/layout/BasicLayout.vue';

const objectiveTECRoutes = {
  path: '/production-objective',
  name: 'productionObjective',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'technical',
    code: 'quality-production-objective',	
    title: '生产经营目标任务',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/production-objective',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '生产经营目标任务',
        menu: false
      }
    },
  ]
};

export default objectiveTECRoutes;
