<template>
  <div>
    <div>
      <div class="screenHeader">
        <div>
          <img class="headericon" src="~assets/images/screen/headericon.png" alt="" />
          <img class="iconright" src="~assets/images/screen/iconright.png" alt="" />
        </div>
        <div @click="changeView('tunnel')" :class="!isTunnel ? 'midtext' : 'midtext midtextbg'">
          <img :src="isTunnel ? tunnelOn : tunnelOff" alt="" /><span style="margin-left: 10px">隧道可视化监控大屏</span>
        </div>
        <div @click="changeView('map')" :class="isTunnel ? 'lefttext' : 'lefttext lefttextbg'">
          <img :src="!isTunnel ? mapOn : mapOff" alt="" /><span style="margin-left: 10px">应急调度大屏</span>
        </div>
        <div class="righttext">
          <div class="day">
            <span class="year">{{ getYear }}</span>
            <span class="week">{{ getWeek | week }}</span>
          </div>
          <div>
            <span class="time">{{ time }}</span>
          </div>
        </div>
      </div>
      <div>
        <router-link :to="system" class="exit">
          <img src="~assets/images/screen/exit.png" alt="" />
          <span>进入系统</span>
        </router-link>
      </div>
    </div>
    <div class="headerb"><img src="~assets/images/screen/headerb.png" alt="" style="width: 100%;" /></div>
    <div>
      <router-view @screenGetCenter="screenGetCenter" :center.sync="center"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isTunnel: true, //是否隧道监控大屏
      system: '/home-page', //退出到主页
      getYear: new Date().toLocaleDateString(),
      getWeek: new Date().getDay(),
      time: '',
      center: { lng: 104.06584, lat: 30.65787 }, //默认坐标
      tunnelOn: require('@/assets/images/screen/tunnelon.png'),
      tunnelOff: require('@/assets/images/screen/tunneloff.png'),
      mapOn: require('@/assets/images/screen/mapon.png'),
      mapOff: require('@/assets/images/screen/mapoff.png')
    };
  },
  methods: {
    /* 切换大屏 */
    changeView(val) {
      if (val === 'tunnel') {
        this.isTunnel = true;
      } else {
        this.isTunnel = false;
      }
      this.$nextTick(() => {
        this.$router.push(this.isTunnel ? '/tunnel-monitoring' : '/emergency-supplies');
      });
    },
    /* 刷新不跳转 */
    monitorrouter() {
      if (this.$route.meta.code === 'emergency-supplies') {
        this.isTunnel = false;
      } else {
        this.isTunnel = true;
      }
    },
    /* 动态时间 */
    gettime() {
      this.time = new Date().toLocaleTimeString().replace(/:/g, ' : '); //首次加载避免延迟一秒
      var that = this;
      const timer = setInterval(() => {
        that.time = new Date().toLocaleTimeString().replace(/:/g, ' : ');
      }, 1000);
      this.$once('hook:beforeDestory', () => {
        clearInterval(timer);
        timer = null;
      }); //销毁前，清除定时器
    },
    /* 接受子组件回馈的坐标 */
    screenGetCenter(val) {
      this.center = val;
    }
  },
  mounted() {
    this.monitorrouter();
    this.gettime();
  },
  computed: {},
  filters: {
    week(val) {
      var weekday = ['日', '一', '二', '三', '四', '五', '六'];
      return '星期' + weekday[val];
    }
  }
};
</script>
<style lang="less" scoped>
.screenHeader {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 64px;
  background-image: url('~@/assets/images/screen/topheader.png');
  background-size: 100% 100%;
  pointer-events: none;
  .lefttext {
    position: absolute;
    left: 54.3%;
    top: 0;
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    pointer-events: all;
    width: 316px;
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: center;
  }
  .lefttextbg {
    background-image: url('~@/assets/images/screen/pannelchose.png');
    background-size: 100% 100%;
    font-weight: bold;
  }
  .midtext {
    position: absolute;
    left: 46%;
    top: 0;
    transform: translate(-50%);
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold;
    text-align: center;
    color: #ffffff;
    line-height: 48px;
    pointer-events: all;
    cursor: pointer;
    width: 275px;
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: center;
  }
  .midtextbg {
    background-image: url('~@/assets/images/screen/pannelchose.png');
    background-size: 100% 100%;
    font-weight: bold;
  }
  .righttext {
    position: absolute;
    right: 180px;
    top: 8px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    .day {
      display: flex;
      flex-direction: column;
    }
    .year {
      opacity: 0.8;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: left;
      color: #19ecff;
      line-height: 17px;
    }
    .week {
      opacity: 0.8;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      text-align: left;
      color: #19ecff;
      line-height: 17px;
      margin-top: 5px;
    }
    .time {
      font-weight: bold;
      opacity: 0.8;
      font-size: 24px;
      font-family: MicrosoftYaHei-Bold;
      text-align: left;
      color: #19ecff;
      line-height: 29px;
      margin-left: 23px;
    }
  }
}
.exit {
  position: absolute;
  right: 20px;
  top: 22px;
  z-index: 11;
  margin-right: 11px;
  cursor: pointer;
  text-decoration-line: none;
  font-weight: 700;
  color: #1790fb;
  border-left: 1px solid #005aab;
  padding-left: 20px;
  display: flex;
  align-items: center;
  > span {
    margin-left: 5px;
  }
}
.headericon {
  width: 33.4%;
  height: 100px;
  margin-top: -10px;
  margin-left: -14px;
  object-fit: fill;
}
.iconright {
  position: absolute;
  top: 20px;
  pointer-events: none;
}
.headerb {
  position: absolute;
  right: 0;
  width: 67%;
  height: 5px;
  top: 60px;
  z-index: 10;
  pointer-events: none;
}
</style>
