
import BasicLayout from '@/views/layout/BasicLayout.vue';

const equipmentRoutes = {
  path: '/equip-facility',
  name: 'EquipFacility',
  component: BasicLayout,
  redirect: '/device-manage',
  meta: {
    roleType: 'security',
    code: 'equipment-facilities',
    title: '装备设施',
    menu: true,
    icon: 'iconfont icon-caidan2-5zhuangbeisheshi'
  },
  children: [
    {
      path: '/device-manage',
      name: 'DeviceManage',
      component: () => import('@/views/pages/general-user/security-pages/equip-facilities'),
      meta: {
        roleType: 'security',
        code: 'device-manage_list',
        title: '装备设施管理',
        menu: true
      }
    }
  ]
};

export default equipmentRoutes;
