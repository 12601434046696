<template>
  <div class="echart-line" ref="echartKey"></div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent
]);
export default {
  name: 'echartsLine', //柱状图或者折线图
  props: {
    xData: {
      //横坐标的显示值
      type: Array,
      required: true
    },
    min: {
      //y轴最小值
      type: Number,
      required: false,
      default: 1
    },
    //x轴文字倾斜角度
    xRotate: {
      type: Number,
      required: false,
      default: -20
    },
    dataList: {
      //显示数据
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      myChart: null
    };
  },
  mounted() {
    this.$once('hook:beforeDestroy', () => {
      this.myChart.resize;
    });
  },
  methods: {
    initEchartsLine() {
      this.$refs.echartKey.removeAttribute('_echarts_instance_'); //重新渲染图表
      this.myChart = echarts.getInstanceByDom(this.$refs.echartKey);
      if (this.myChart == null) {
        // 如果不存在，就进行初始化
        this.myChart = echarts.init(this.$refs.echartKey);
      }
      /* 图表响应式 */
      window.onresize = this.myChart.resize;
      var option = {
        //提示框组件
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            //去掉移动的指示线
            type: 'line'
          }
        },
        color: ['#1890FF', '#FD7B26', '#36D68D', '#2FC0CD', '#3D26A9', '#2EAF6A'],
        legend: {
          type: 'scroll',
          textStyle: {
            fontSize: '14px',
            color: '#979FA8' //字体颜色
          },
          left: 'center',
          bottom: '0',
          itemWidth: 16, // 设置宽度
          itemHeight: 16, // 设置高度
          itemGap: 20 // 设置间距,
        },
        grid: {
          left: '40',
          right: '0',
          bottom: '60',
          containLabel: true
        },
        //横坐标
        xAxis: {
          type: 'category',
          data: this.xData,
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#DCDFE7',
              width: '1'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            rotate: this.xRotate,
            textStyle: {
              color: '#C0C4CE'
            }
          }
        },
        //纵坐标
        yAxis: {
          name: '',
          max: this.max,
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: '#C0C4CE'
            }
          },
          minInterval: this.min,
          splitLine: {
            lineStyle: {
              color: '#f6f6f6'
            }
          }
        },
        dataZoom: [
          {
            show: true,
            type: 'slider', // 滑动条
            xAxisIndex: [0],
            realtime: true,
            start: 0,
            end: 50,
            height: 10,
            zoomLock: true,
            minValueSpan: 5,
            filterMode: 'none',
            showDetail: false,
            bottom: '40',
            handleStyle: {
              color: '#0B3C6F',
              shadowBlur: 3,
              shadowColor: 'rgba(0, 0, 0, 0.6)',
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            zoomOnMouseWheel: false, // 滚轮不触发缩放
            moveOnMouseMove: false, // 鼠标移动触发平移
            moveOnMouseWheel: false, // 鼠标滚轮触发平移
            /* 这里很重要 */
            zoomLock: true,
            minValueSpan: 5,
            bottom: '40'
          }
        ],
        series: this.dataList
      };
      this.myChart.setOption(option);
    }
  }
};
</script>

<style lang="less" scoped>
.echart-line {
  height: 640px;
  width: 100%;
}
</style>
