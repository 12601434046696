/**
 * @method 导出方法
 * @param {Object} res 后端返回的数据
 * //@param {string} name 需要定义的名字
 */
export function exportFun(res) {
  /* const blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' }); // res.data是后台返回的内容
  let fileName = decodeURI(res.headers['content-disposition'].split('attachment; filename=')[1]); //这里获取的文件名前后都会都个_
  let exportFileName = fileName.substr(1, fileName.length - 2);
  const objectUrl = URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = objectUrl;
  a.download = exportFileName;
  a.click();
  document.body.removeChild(downloadElement);
  URL.revokeObjectURL(objectUrl); // 释放内存 */

  var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' });
  var contentDisposition = res.headers['content-disposition'];
  var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
  var result = patt.exec(contentDisposition);
  var filename = result[1];
  var downloadElement = document.createElement('a');
  var href = window.URL.createObjectURL(blob); // 创建下载的链接
  var reg = /^["](.*)["]$/g;
  downloadElement.style.display = 'none';
  downloadElement.href = href;
  downloadElement.download = decodeURI(filename.replace(reg, '$1')); // 下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href);
}
