import Train from '@/views/pages/general-user/health-pages/health-train';
import BasicLayout from '@/views/layout/BasicLayout.vue';

const TrainRoutes = {
  path: '/health-train',
  name: 'HealthTrain',
  component: BasicLayout,
  redirect: '/health-trainlist',
  meta: {
    roleType: 'health',
    code: 'health-train',
    title: '职业卫生培训',
    menu: true,
    icon: 'icon-zcaidan1-5zhiyeweishengpeixun'
  },
  children: [{
    path: '/health-train',
    name: 'HealthTrainList',
    component: Train,
    meta: {
      roleType: 'health',
      code: 'health-train-list',
      title: '职业卫生培训列表',
      menu: false
    }
  }]
};

export default TrainRoutes;
