import BasicLayout from '@/views/layout/BasicLayout.vue';

const occupationalEnvRoutes = {
  path: '/env-files',
  name: 'EnvFiles',
  component: BasicLayout,
  redirect: '/env-files-view',
  meta: {
    roleType: 'environment',
    code: 'env-files',
    title: '环保档案',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-files-view',
      name: 'EnvFilesView',
      component: () => import('@/views/pages/general-user/env-pages/files-pages/data-list'),
      meta: {
        roleType: 'environment',
        code: 'env-files-view',
        title: '环保档案',
        menu: true
      }
    },
    {
      path: '/env-operation-log',
      name: 'EnvOperationLog',
      component: () => import('@/views/pages/general-user/env-pages/files-pages/action-log'),
      meta: {
        roleType: 'environment',
        code: 'env-operation-log',
        title: '操作日志',
        menu: true
      }
    }
  ]
};

export default occupationalEnvRoutes;
