import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/home-page',
  name: 'homePage',
  component: BasicLayout,
  redirect: '/home-page',
  meta: {
    roleType: 'security',
    code: 'home',
    title: '首页',
    menu: false,
    icon: 'icon-caidan1-5xitongguanli'
  },
  children: [
    {
      path: '/home-page',
      name: 'homePageIndex',
      component: () => import('@/views/pages/general-user/security-pages/home-page'),
      meta: {
        roleType: 'security',
        code: 'home-page',
        title: '首页',
        menu: false
      }
    }
  ]
};

export default homePageMgrRoutes;
