<template>
  <div>
    <p class="title">配置项</p>
    <el-menu text-color="#303133" router unique-opened :default-active="$route.path">
      <el-submenu v-for="(item, index) in asideMenuList.key" :key="item.property" :index="index.toString()">
        <template slot="title">
          <span class="item-title">{{ item.title }}</span>
        </template>
        <el-menu-item :index="single.path" v-for="single in item.menuList" :key="single.path">
          <span slot="title">{{ single.name }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      asideMenuList: {
        key: [
          {
            property: 'security',
            title: '安全管理',
            menuList: [
              {
                name: '内页资料配置',
                path: '/sys-setting'
              },
              {
                name: '危险源配置',
                path: '/security_hazard-setting'
              },
              {
                name: '风险点配置',
                path: '/security_risk-setting'
              },
              {
                name: '隐患配置',
                path: '/security_hidden-setting'
              },
              {
                name: '施工安全检查评分表配置',
                path: '/security_score-setting'
              },
              {
                name: '安全经费配置',
                path: '/security_safety-setting'
              },
              {
                name: '应急演练配置',
                path: '/security_meet-setting'
              },
              {
                name: '履职岗位配置',
                path: '/security_performance-setting'
              }
            ]
          },
          {
            property: 'environment',
            title: '环保管理',
            menuList: [
              {
                name: '环保档案配置',
                path: '/env_occupational-setting'
              },
              {
                name: '隐患配置',
                path: '/env_hidden-setting'
              }
            ]
          },
          {
            property: 'health',
            title: '职业健康管理',
            menuList: [
              {
                name: '职业健康档案配置',
                path: '/health_occupational-setting'
              },
              {
                name: '隐患配置',
                path: '/health_hideen-setting'
              }
            ]
          }
        ]
      }
    };
  }
};
</script>
<style scoped>
.title {
  font-size: 16px;
  padding-left: 8px;
  margin-bottom: 28px;
}
.item-title {
  font-weight: 700;
}
.el-menu {
  border-right: none;
}
.el-menu-item.is-active {
  background: rgba(17, 123, 247, 0.1);
}
</style>
