<!--
 * @Author: your name
 * @Date: 2021-11-08 11:06:56
 * @LastEditTime: 2021-11-09 11:43:01
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\layout\Header.vue
-->
<template>
  <div class="header">
    <div class="company-box" @click="pushRouter">
      <img :src="companyLogo" alt="公司logo" />
      <!-- <h1 class="title">四川路桥建设集团股份有限公司</h1> -->
    </div>
    <div class="propaganda-text">
      <p></p>
    </div>
    <div>
      <router-link :to="screenView" class="screenLink" v-if="userInfo.adminType !== 2 && $check('screen-view')"
        ><img style="height: 70px;position: absolute;top: 0;right: 200px;" :src="screenImg"
      /></router-link>
    </div>
    <el-dropdown>
      <span class="el-dropdown-link">
        <div class="user-info">
          <img :src="userAvatar" alt="用户logo" />
          你好，{{ userInfo.name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-if="userInfo.adminType !== 2 && $check('personal-center')" @click.native="goPersonalCenter"
          >个人中心</el-dropdown-item
        >
        <el-dropdown-item @click.native="toggleSys">切换系统</el-dropdown-item>
        <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import onLogout from '@/utils/onLogout.js';

export default {
  data() {
    return {
      userInfo: {},
      companyLogo: require('assets/images/header/company_logo.png'),
      userAvatar: require('assets/images/header/user_avatar.png'),
      screenImg: require('assets/images/header/screenImg.png'),
      screenView: '/screen-page' //默认大屏
    };
  },
  created() {
    this.userInfo = getItem('userInfo');
  },
  methods: {
    handleLogout() {
      onLogout();
    },
    toggleSys() {
      if (process.env.NODE_ENV === 'development') {
        window.location.href = `${window.location.protocol}//${window.location.hostname}:8090/#/register/systemselect`;
      } else {
        window.location.href = 'http://36.138.233.147:8070/#/register/systemselect';
      }
    },
    pushRouter() {
      this.$router.push('/home-page');
    },
    goPersonalCenter() {
      this.$router.push('/personal-center');
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: #005aab;
  .company-box {
    /*  display: flex;
    align-items: center; */
    .title {
      margin: 0;
      font-size: 24px;
      font-style: italic;
      letter-spacing: 2px;
      cursor: pointer;
    }
    h1 {
      font-size: 24px;
      margin-right: 36px;
    }
    img:first-child {
      display: block;
      width: 100%;
      height: 50px;
      position: relative;
      // top: 5px;
    }
  }
  .propaganda-text {
    p {
      font-size: 28px;
      font-weight: bold;
    }
  }

  .el-dropdown {
    color: #005aab;
    cursor: pointer;
  }
  .user-info {
    display: flex;
    align-items: center;
    color: #005aab;
    font-weight: bold;
    img {
      vertical-align: middle;
      margin-right: 6px;
    }
  }
}
.screenLink {
  text-decoration: none;
  color: #005aab;
}
</style>
