export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属机构'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入岗位'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入职业病危害因素'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入接触时长'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入计算结果最大值'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入监测结果'));
      }else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateHiddenCategoryId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择判定结论'));
      }
      callback();
    };

    return {
      rules: {
        orgId: [{ validator: validateOrgId, trigger: '' }],
        post: [{ validator: validatePost, trigger: '' }],
        hazards: [{ validator: validateProblem, trigger: '' }],
        contactTime: [{ validator: validateSuggestion, trigger: '' }],
        maxResult: [{ validator: validateHiddenLevelId, trigger: '' }],
        results: [{ validator: validateHiddenTypeId, trigger: '' }],
        conclusion: [{ validator: validateHiddenCategoryId, trigger: '' }],
      }
    };
  }
};
