<template>
  <div>
    <!-- 选择机构 -->
    <div class="target-org">
      <el-form inline label-width="80px">
        <el-form-item label="配置机构">
          <el-select
            v-model="orgId"
            filterable
            placeholder="请选择或输入以搜索"
            style="width: 380px"
            @change="changeOrgName"
          >
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-container class="basic-layout" v-if="isRouterAlive">
      <el-container>
        <el-aside width="240px">
          <aside-menu />
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { setItem } from '@/utils/storage.js';
import { getOrgList } from 'api/super-admin/sys-setting/data-setting.js';
import AsideMenu from './AsideMenu.vue';

export default {
  components: { AsideMenu },
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      orgList: [],
      orgId: '',
      isRouterAlive: false
    };
  },
  methods: {
    reload() {
      //相当于重置
      this.isRouterAlive = false;
      setItem('orgId', this.orgId);
      this.$router.push('/sys-setting');
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    changeOrgName() {
      this.reload();
    },
    getOrgListData() {
      getOrgList().then(res => {
        if (res.code === 200) {
          this.orgList = res.data;
        }
      });
    }
  },
  created() {
    this.getOrgListData();
  }
};
</script>
<style lang="less" scoped>
.basic-layout {
  height: 75vh;
  .el-aside {
    border: 1px solid #dcdfe7;
  }
  /deep/ .el-main {
    padding: 0 16px;
    background-color: #fff;
  }
}
</style>
