import BasicLayout from '@/views/layout/BasicLayout.vue';

const internalDataRoute = {
  path: '/internal-data',
  name: 'InternalData',
  component: BasicLayout,
  redirect: '/data-list',
  meta: {
    roleType: 'security',
    code: 'standard-construction',
    title: '标准化建设',
    menu: true,
    icon: 'iconfont icon-caidan2-1neiyeziliao'
  },
  children: [
    {
      path: '/data-list',
      name: 'DataList',
      component: () => import('@/views/pages/general-user/security-pages/internal-data/data-list'),
      meta: {
        roleType: 'security',
        code: 'data-list',
        title: '资料清单',
        menu: true
      }
    },
    {
      path: '/action-log',
      name: 'ActionLog',
      component: () => import('@/views/pages/general-user/security-pages/internal-data/action-log/index.vue'),
      meta: {
        roleType: 'security',
        code: 'action-log',
        title: '操作日志',
        menu: true
      }
    }
  ]
};

export default internalDataRoute;
