/*
 * @Author: your name
 * @Date: 2021-11-09 10:25:44
 * @LastEditTime: 2021-12-07 11:07:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\router\general-uesr\3.visualization.js
 */
import BasicLayout from '@/views/layout/BasicLayout.vue';

const homePageMgrRoutes = {
  path: '/visualization',
  name: 'visualization',
  component: BasicLayout,
  redirect: '/visualization',
  meta: {
    roleType: 'security',
    code: 'visualization',
    title: '可视化',
    menu: true,
    icon: 'iconfont icon-caidan2-3keshihua'
  },
  children: [
    {
      path: '/video-monitor',
      name: 'videoMonitor',
      component: () => import('@/views/pages/general-user/security-pages/visualization/video-monitor'),
      meta: {
        roleType: 'security',
        code: 'video-monitor',
        title: '视频监控',
        menu: true
      }
    },
    {
      path: '/video-control',
      name: 'videoControl',
      component: () => import('@/views/pages/general-user/security-pages/visualization/video-control'),
      meta: {
        roleType: 'security',
        code: 'video-control_list',
        title: '摄像机管理',
        menu: true
      }
    },
    {
      path: '/access-identity',
      name: 'accessIdentity',
      component: () => import('@/views/pages/general-user/security-pages/visualization/access-identity'),
      meta: {
        roleType: 'security',
        code: 'access-identity_list',
        title: '门禁识别',
        menu: true
      }
    },
    {
      path: '/access-control',
      name: 'accessControl',
      component: () => import('@/views/pages/general-user/security-pages/visualization/access-control'),
      meta: {
        roleType: 'security',
        code: 'access-control_list',
        title: '门禁管理',
        menu: true
      }
    },
    ,
    {
      path: '/on-wall',
      name: 'onWall',
      component: () => import('@/views/pages/general-user/security-pages/visualization/on-wall'),
      meta: {
        roleType: 'security',
        code: 'on-wall_list',
        title: '上墙管理',
        menu: true
      }
    }
  ]
};

export default homePageMgrRoutes;
