import BasicLayout from '@/views/layout/BasicLayout.vue';

const TeamEnvRoutes = {
  path: '/env-team',
  name: 'EnvTeam',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-team',
    title: '队伍建设',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-training',
      name: 'EnvTraining',
      component: () => import('@/views/pages/general-user/env-pages/training'),
      meta: {
        roleType: 'environment',
        code: 'env-training',
        title: '环保教育培训',
        menu: true
      }
    }
  ]
};

export default TeamEnvRoutes;
