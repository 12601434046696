import BasicLayout from '@/views/layout/BasicLayout.vue';

const sysManagementRoute = {
  path: '/sys-management',
  name: 'SysManagement',
  component: BasicLayout,
  redirect: '/org-management',
  meta: {
    roleType: 'security',
    code: 'sys-mgt',
    title: '系统管理',
    menu: true,
    icon: 'iconfont icon-caidan2-8xitongguanli'
  },
  children: [
    {
      path: '/org-management',
      name: 'OrgManagement',
      component: () => import('@/views/pages/general-user/security-pages/sys-management/org-management/index.vue'),
      meta: {
        roleType: 'security',
        code: 'org-mgt',
        title: '机构管理',
        menu: true
      }
    },
    {
      path: '/user-management',
      name: 'UserManagement',
      component: () => import('@/views/pages/general-user/security-pages/sys-management/user-management/index.vue'),
      meta: {
        roleType: 'security',
        code: 'user-mgt',
        title: '人员管理',
        menu: true
      }
    },
    {
      path: '/role-management',
      name: 'RoleManagement',
      component: () => import('@/views/pages/general-user/security-pages/sys-management/role-management/index.vue'),
      meta: {
        roleType: 'security',
        code: 'role-mgt',
        title: '角色管理',
        menu: true
      }
    },
    {
      path: '/personal-center',
      name: 'PersonalCenter',
      component: () => import('@/views/pages/general-user/security-pages/sys-management/personal-center/index.vue'),
      meta: {
        roleType: 'security',
        code: 'personal-center',
        title: '个人中心',
        menu: true
      }
    }
  ]
};

export default sysManagementRoute;
