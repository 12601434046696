import BasicLayout from '@/views/layout/BasicLayout.vue';

const PreventionEnvRoutes = {
  path: '/env-double-prevention',
  name: 'EnvDoublePrevention',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'environment',
    code: 'env-double-prevention',
    title: '双重预防',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children: [
    {
      path: '/env-risk-manage',
      name: 'EnvRiskManage',
      component: () => import('@/views/pages/general-user/env-pages/double-prevention/risk-manage'),
      meta: {
        roleType: 'environment',
        code: 'env-risk-manage',
        title: '环境风险管理',
        menu: true
      }
    },
    {
      path: '/env-water-dangers',
      name: 'EnvWaterDangers',
      component: () => import('@/views/pages/general-user/env-pages/double-prevention/danger-treat'),
      meta: {
        roleType: 'environment',
        code: 'env-water-dangers',
        title: '环水保隐患管理',
        menu: true
      }
    }
  ]
};

export default PreventionEnvRoutes;
