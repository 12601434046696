<template>
  <div>
    <el-upload
      :action="uploadUrl"
      :headers="uploadHeaders"
      multiple
      :file-list="defaultList"
      :data="{ appBucketName: 'traffic-construction' }"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-remove="handleRemove"
      :on-progress="handelProgress"
      :on-error="onError"
      v-loading.fullscreen="loading"
      element-loading-text="拼命上传中"
      element-loading-background="rgba(0, 0, 0, 0.1)"
    >
      <span class="action-btn">点击上传附件</span>
    </el-upload>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
export default {
  props: {
    defaultList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    uploadUrl() {
      return '/api/roses-pub-service/sysFileInfo/uploadResponse';
    },
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.token,
        AppCode: 'traffic-construction',
        tenantId: this.tenantId
      };
    }
  },
  data() {
    return {
      fileIdList: [],
      loading: false
    };
  },
  methods: {
    //删除
    handleRemove(file, fileList) {
      let removeFileId = file.fileId;
      this.fileIdList.splice(this.fileIdList.indexOf(removeFileId), 1);
      this.$emit('get-file-data', this.fileIdList);
    },
    //上传前校验
    beforeUpload(file) {
      let extension = file.name.split('.')[file.name.split('.').length-1];//优化避免多个.导致文件类型错误
      const requireType = ['doc', 'docx', 'ppt', 'pptx', 'pps', 'xls', 'xlsx', 'pdf', 'txt', 'png', 'jpg', 'jpeg'];
      const isRequireType = requireType.indexOf(extension) >= 0;
      if (!isRequireType) {
        this.$message.error('上传文件类型错误!');
      }
      return isRequireType;
    },
    //上传中
    handelProgress(event, file, fileList) {
      this.loading = true;
    },
    //上传失败
    onError(err, file, fileList) {
      this.loading = false;
    },
    //上传成功
    handleSuccess(response, file, fileList) {
      setTimeout(() => {
        this.loading = false;
      }, 500);
      this.fileIdList.push(response.data.fileId);
      this.$emit('get-file-data', this.fileIdList);
      // this.$emit('get-file-detail', response.data);
    }
  },
  created() {
    this.token = getItem('token');
    this.tenantId = getItem('tenantInfo').tenantId;
    //查看详情下
    if (this.defaultList && this.defaultList.length) {
      this.defaultList.forEach(item => {
        this.fileIdList.push(item.fileId);
      });
    }
  }
};
</script>
