import jumpSmartPark from '@/utils/jump-smartpark';
import { clearAll } from '@/utils/storage.js';
import { logout } from '@/api/common/index.js';

export default function onLogout() {
  logout().finally(() => {
    clearAll();
    jumpSmartPark();
  });
};
