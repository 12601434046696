let generalEnvRoutes = [];

const requireComponent = require.context('./', false, /\.js$/);
// console.log(requireComponent.keys());
requireComponent.keys().forEach(item => {
  if (item !== './index.js') {
    const route = requireComponent(item).default;
    generalEnvRoutes = generalEnvRoutes.concat(route);
  }
});

export default generalEnvRoutes;
