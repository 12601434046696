import { render, staticRenderFns } from "./AsideMenu.vue?vue&type=template&id=2452dae4&scoped=true&"
import script from "./AsideMenu.vue?vue&type=script&lang=js&"
export * from "./AsideMenu.vue?vue&type=script&lang=js&"
import style0 from "./AsideMenu.vue?vue&type=style&index=0&id=2452dae4&lang=less&scoped=true&"
import style1 from "./AsideMenu.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2452dae4",
  null
  
)

export default component.exports