import BasicLayout from '@/views/layout/BasicLayout.vue';

const planTECRoutes = {
  path: '/plan-manage',
  name: 'planManage',
  component: BasicLayout,
  redirect: '',
  meta: {
    roleType: 'technical',
    code: 'quality-plan-manage',	
    title: '施工技术方案管理',
    menu: true,
    icon: 'icon-caidan2-9zerenqingdan'
  },
  children:[
    {
      path: '/plan-manage',
      name: 'qualityRiskList',
      component: () => import('@/views/pages/general-user/quality-manage'),
      meta: {
        roleType: 'quality',
        code: 'quality-quality-risk-list',
        title: '施工技术方案管理',
        menu: false
      }
    },
  ]
};

export default planTECRoutes;
