import request from '@/utils/request.js';

export function getMethods(params) {
  return request({
    url: '/vue-element-admin/article/list',
    method: 'get',
    params
  });
}

/**
 * 获取登录用户信息
 * @returns {Promise}
 */
export function getLoginUser(params) {
  return request({
    url: '/traffic-construction/sysUser/onlineUserDetail',
    method: 'get',
    params
  });
}

/**
 * 获取机构树
 * @returns {Promise}
 */
export function getOrgTree() {
  return request({
    url: '/traffic-construction/orgTree',
    method: 'get'
  });
}

/**
 * 机构树拖拽排序
 * @returns {Promise}
 */
export function changeOrder(data) {
  return request({
    url: '/traffic-construction/changeOrder',
    method: 'post',
    data
  });
}

/**
 * 获取机构下拉列表
 * @returns {Promise}
 */
export function getOrgList() {
  return request({
    url: '/traffic-construction/getOrgList',
    method: 'get'
  });
}

/**
 * 获取菜单树
 * @returns {Promise}
 */
export function getGrantTree() {
  return request({
    url: '/traffic-construction/sysMenu/treeForGrant',
    method: 'get'
  });
}

/**
 * 登出
 * @returns {Promise}
 */
export function logout() {
  return request({
    url: '/roses-platform/logout',
    method: 'get'
  });
}

/**
 * 上传文件
 * @returns {Promise}
 */
export function upload(data) {
  return request({
    url: '/roses-pub-service/sysFileInfo/uploadResponse',
    method: 'post',
    data
  });
}

/**
 * 获取所有的项目部列表(第三方单位的获取所有，非第三方按照直属机构性质获得不同的数据范围)
 * @returns {Promise}
 */
export function getProjectDepartmentList(params) {
  return request({
    url: '/traffic-construction/getProjectDepartmentList',
    method: 'get',
    params
  });
}

/**
 * 配置下拉列表
 * @returns {Promise}
 */
export function configDetailList(params) {
  return request({
    url: '/traffic-construction/configurationDetail/configDetailMapList',
    method: 'get',
    params
  });
}

/**
 * 所属项目下拉列表（新增隐患时使用）
 * @returns {Promise}
 */
export function getProjectNameAndId(params) {
  return request({
    url: '/traffic-construction/getProjectNameAndId',
    method: 'get',
    params
  });
}

/**
 * 所属机构下拉列表（新增隐患时使用）
 * @returns {Promise}
 */
export function getCompanyNameAndId(params) {
  return request({
    url: '/traffic-construction/getCompanyNameAndId',
    method: 'get',
    params
  });
}

/**
 * 所属标段下拉列表（新增隐患时使用）
 * @returns {Promise}
 */
export function getBidSectionNameAndId(params) {
  return request({
    url: '/traffic-construction/getBidSectionNameAndId',
    method: 'get',
    params
  });
}

/**
 * 获取自身权限下全部机构、标段、项目部等
 * @returns {Promise}
 */
export function getDoorControlOrgList(params) {
  return request({
    url: '/traffic-construction/doorControl/getOrgList',
    method: 'get',
    params
  });
}

/**
 * 所属项目部下拉列表（隐患分页筛选时使用）
 * @returns {Promise}
 */
export function getAllProjectDepartment(params) {
  return request({
    url: '/traffic-construction/getAllProjectDepartment',
    method: 'get',
    params
  });
}

/**
 * 获取完整机构树
 * @returns {Promise}
 */
export function getOrgTreeAllData(params) {
  return request({
    url: '/traffic-construction/getCompleteOrgTree',
    method: 'get',
    params
  });
}
/* 第三版 */
/**
 * 获取显示层级
 * @returns {Promise}
 */
export function getDataRang(params) {
  return request({
    url: '/traffic-construction/getDataRang',
    method: 'get',
    params
  });
}

/**
 * 获取项目部
 * @returns {Promise}
 */
export function getDataRangByNature(params) {
  return request({
    url: '/traffic-construction/getDataRangByNature',
    method: 'get',
    params
  });
}

/* 系统配置表格拖拽排序 */

export function configEditSort(data) {
  return request({
    url: '/traffic-construction/configurationDetail/editSort',
    method: 'post',
    data
  });
}
