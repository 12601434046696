/*
 * @Author: your name
 * @Date: 2021-11-08 11:06:56
 * @LastEditTime: 2021-11-09 09:57:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\permission.js
 */
import router from './router';
import { Notification } from 'element-ui';
// 进度条
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';

import { getItem } from '@/utils/storage.js';
import getPageTitle from '@/utils/get-page-title.js';
import onLogout from '@/utils/onLogout.js';
// 白名单
const WHITE_LIST = ['Login'];

Nprogress.configure({
  showSpinner: false
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // debugger;
  Nprogress.start();
  // FIXME: 成功后才设置set title
  document.title = getPageTitle(to.meta.title);

  const token = getItem('token');
  /*  has token */
  if (token) {
    if (to.name === 'Login') {
      next();
      Nprogress.done();
      return;
    }
    const  SETroleType=['security','health','environment','OA','technical','scientific','quality'];//子系统关键字,用于判断to.meta.roleType
    // TODO: ☆ 权限判断
    const { adminType } = getItem('userInfo');
    // debugger;
    if (adminType === 2 && to.meta.roleType === 'super') {
      next();
    } else if ((adminType === 0 && SETroleType.includes(to.meta.roleType))) {
      next();
    } else if (adminType === 1) {
      const codeList = getItem('permissionCodeList');
      if (codeList.includes(to.meta.code)) {
        next();
      } else {
        Notification.warning({
          title: '提示：',
          message: '暂无权限访问该页面，请联系管理员！',
          duration: 3000,
          onClose: () => {
            // next('/403');
            Nprogress.done();
          }
        });
      }
    } else {
      Notification.warning({
        title: '提示：',
        message: '暂无权限访问该页面，请联系管理员！',
        duration: 3000,
        onClose: () => {
          // next('/403');
          Nprogress.done();
        }
      });
    }
    return;
  }

  /* has no token */
  if (WHITE_LIST.includes(to.name)) {
    next();
  } else {
    Notification.warning({
      title: '提示：',
      message: '登录已过期，请重新登录。',
      duration: 3000,
      onClose: () => {
        onLogout();
      }
    });
    Nprogress.done();
  }
});

router.afterEach(() => {
  Nprogress.done();
});
