export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入接触职业病危害因素'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入个人防护用品名称'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入型号'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入数量'));
      }else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入领取人'));
      }else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateHiddenCategoryId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择领取日期'));
      }
      callback();
    };

    return {
      rules: {
        projectId: [{ validator: validateOrgId, trigger: '' }],
        hazards: [{ validator: validatePost, trigger: '' }],
        material: [{ validator: validateProblem, trigger: '' }],
        model: [{ validator: validateSuggestion, trigger: '' }],
        num: [{ validator: validateHiddenLevelId, trigger: '' }],
        recipients: [{ validator: validateHiddenTypeId, trigger: '' }],
        collectionDate: [{ validator: validateHiddenCategoryId, trigger: '' }],
      }
    };
  }
};
