import request from '@/utils/request.js';
/**
 * 获取文件列表
 * @returns {Promise}
 */
export function getFileList(params) {
  return request({
    url: '/traffic-construction/folder/getFolderList',
    method: 'get',
    params
  });
};

/**
 * 获取操作日志
 * @returns {Promise}
 */
export function getLogList(params) {
  return request({
    url: '/traffic-construction/opLog/list',
    method: 'get',
    params
  });
};

/**
 * 上传文件到文件夹
 * @returns {Promise}
 */
export function addFileToFolder(data) {
  return request({
    url: '/traffic-construction/fileFolder/add',
    method: 'post',
    data
  });
};

/**
 * 删除文件
 * @returns {Promise}
 */
export function deleteFile(data) {
  return request({
    url: '/traffic-construction/fileFolder/delete',
    method: 'post',
    data
  });
};

/**
 * 下载文件
 * @returns {Promise}
 */
export function downloadFile(params) {
  return request({
    url: '/traffic-construction/fileFolder/down',
    method: 'get',
    responseType: 'blob',
    params
  });
};
