import { getItem } from '@/utils/storage.js';
/**
 * 权限校验
 * @param {String} code 当前按钮权限 code
 * @returns {Boolean} 用户是否有此按钮的权限
 */
export default function checkPermission(code) {
  const permissionList = getItem('permissionCodeList');
  return permissionList.includes(code);
};
